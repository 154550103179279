import { Database } from "bun:sqlite";
import { ulid } from "ulid";
import { Logger } from "../logger";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { BaseDatabase, Paginated } from "./root";
import { NotFoundError } from "elysia";

export interface Banner {
  id: string;
  name: string;
  type: BannerType;
  mediaUrl: string;
  mediaType: "image" | "video";
  blurhash: string;
  priority: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export type BannerType = "main" | "menu" | "branch" | "investment" | "career";

export class BannersDatabase extends BaseDatabase {
  protected db: Database;

  constructor(_db: Database) {
    super(_db);
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("banners table initialized"))
      .catch(() => Logger.error("banners table failed to initialize"));
  }

  // get by id
  getById(id: string): Banner {
    const result = this.db
      .query(`SELECT * FROM banners WHERE id = ?`)
      .get(id) as object;
    return toCamelCase(result) as Banner;
  }

  getLastPriority(type: BannerType): number {
    const result = this.db
      .prepare(
        `SELECT MAX(priority) as lastPriority FROM banners WHERE type = ?`
      )
      .get(type) as { lastPriority: number };
    return result.lastPriority || 0;
  }

  // Get banners paginated
  getPaginated(
    page: number,
    pageSize: number,
    type: BannerType
  ): Paginated<Banner> {
    const results = this.db
      .query(
        `SELECT * FROM banners WHERE type = ? ORDER BY priority ASC LIMIT ? OFFSET ?`
      )
      .all(type, pageSize.toString(), (page * pageSize).toString()) as object[];
    const data = results.map(toCamelCase) as Banner[];

    const totalRows = this.db
      .query(`SELECT COUNT(*) as total FROM banners`)
      .get() as { total: number };

    const metadata = constructPaginationMetadata(totalRows, page, pageSize);

    return { data, metadata };
  }

  getList(type: BannerType): Banner[] {
    const results = this.db
      .query(
        `SELECT * FROM banners WHERE type = ? ORDER BY priority ASC LIMIT 5`
      )
      .all(type) as object[];
    const data = results.map(toCamelCase) as Banner[];

    return data;
  }

  getActive(type: BannerType): Banner[] {
    const results = this.db
      .query(
        `SELECT * FROM banners 
        WHERE is_active = 1
        AND type = ?
        ORDER BY priority ASC LIMIT 5`
      )
      .all(type) as object[];
    return results.map(toCamelCase) as Banner[];
  }

  // Create a new banner
  create({
    name,
    type,
    mediaUrl,
    mediaType,
    blurhash,
    priority,
  }: {
    name: string;
    type: BannerType;
    mediaUrl: string;
    mediaType: string;
    blurhash?: string;
    priority: number;
  }) {
    const result = this.db
      .query(
        `INSERT INTO banners (id, name, type, media_url, media_type, blurhash, priority, is_active, created_at, updated_at) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?) RETURNING id`
      )
      .get(
        ulid(),
        name,
        type,
        mediaUrl,
        mediaType,
        blurhash || null,
        priority,
        "1",
        new Date().toISOString(),
        new Date().toISOString()
      ) as { id: string };

    return result.id as string;
  }

  // Update a banner
  update(
    id: string,
    {
      name,
      type,
      mediaUrl,
      mediaType,
      blurhash,
      isActive,
    }: {
      name?: string;
      type?: BannerType;
      mediaUrl?: string;
      mediaType?: string;
      blurhash?: string;
      isActive?: boolean;
    }
  ) {
    const fieldsToUpdate: string[] = [];
    const values: (string | null)[] = [];

    if (name !== undefined && name !== null) {
      fieldsToUpdate.push("name = ?");
      values.push(name);
    }
    if (type !== undefined && type !== null) {
      fieldsToUpdate.push("type = ?");
      values.push(type);

      // make the priority to be the last if the type is changed
      const banner = this.db
        .prepare(`SELECT * FROM banners WHERE id = ?`)
        .get(id) as Banner;
      if (banner.type !== type) {
        const lastPriority = this.getLastPriority(type);
        fieldsToUpdate.push("priority = ?");
        values.push((lastPriority + 1).toString());
      }
    }
    if (mediaUrl !== undefined && mediaUrl !== null) {
      fieldsToUpdate.push("media_url = ?");
      values.push(mediaUrl);
    }
    if (mediaType !== undefined && mediaType !== null) {
      fieldsToUpdate.push("media_type = ?");
      values.push(mediaType);
    }
    if (blurhash !== undefined && blurhash !== null) {
      fieldsToUpdate.push("blurhash = ?");
      values.push(blurhash);
    }
    if (isActive !== undefined && isActive !== null) {
      fieldsToUpdate.push("is_active = ?");
      values.push(isActive ? "1" : "0");
    }

    // Always update the updated_at field
    fieldsToUpdate.push("updated_at = ?");
    values.push(new Date().toISOString());

    if (fieldsToUpdate.length === 0) {
      return;
    }

    values.push(id.toString());

    const query = `UPDATE banners SET ${fieldsToUpdate.join(
      ", "
    )} WHERE id = ?`;
    this.db.run(query, values);
  }

  private updatePrioritiesAfterDelete(
    deletedPriority: number,
    type: BannerType
  ) {
    this.db.run(
      `
      UPDATE banners
      SET priority = priority - 1
      WHERE priority > ? AND type = ?
      `,
      [deletedPriority, type]
    );
  }

  private reverseNegativePriorities() {
    this.db.run(
      `
      UPDATE banners
      SET priority = -priority
      WHERE priority < 0
      `
    );
  }

  adjustPriorities(orderMap: Map<string, number>): void {
    const query = `
      UPDATE banners
      SET priority = CASE id
      ${Array.from(orderMap)
        .map(([id, priority]) => `WHEN '${id}' THEN -${priority}`)
        .join(" ")}
      ELSE priority
      END
    `;

    this.db.run(query);
    this.reverseNegativePriorities();
  }

  // Delete a banner
  delete(id: string) {
    try {
      // Get the priority of the banner being deleted
      const result = this.db
        .query(`SELECT priority, type FROM banners WHERE id = ?`)
        .get(id) as {
        priority: number;
        type: BannerType;
      };

      if (!result) {
        throw new NotFoundError("Banner tidak ditemukan.");
      }

      const { priority, type } = result;

      // Delete the banner
      this.db.run(`DELETE FROM banners WHERE id = ?`, [id]);

      // Update priorities of other banners
      this.updatePrioritiesAfterDelete(priority, type);
    } catch (error) {
      throw error;
    }
  }

  // Initialize the database
  async init() {
    this.db.run(
      `CREATE TABLE IF NOT EXISTS banners (
      id TEXT NOT NULL PRIMARY KEY,
      name TEXT NOT NULL,
      type TEXT NOT NULL,
      media_url TEXT NOT NULL,
      media_type TEXT NOT NULL,
      blurhash TEXT,
      priority INTEGER DEFAULT 0 NOT NULL,
      is_active BOOLEAN DEFAULT 0 NOT NULL,
      created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
      updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
      UNIQUE(type, priority)
      );`
    );
  }
}
