import { Html } from "@elysiajs/html";
import { Branch } from "../../../lib/db/branch";
import { generateBlurhashDataUrl } from "../../../utils/blurhash";
import { convertBaseURL } from "../../../utils/url";
import { Dropzone, DropzoneImage } from "../../dropzone";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { TextArea } from "../../ui/textarea";
import { Button } from "../../button";

export async function UpdateBranchForm({ branch }: { branch: Branch }) {
  const dataUrl = await generateBlurhashDataUrl(branch.blurhash, 32, 32);
  const images: DropzoneImage[] = [];
  if (branch.imageUrl) {
    branch.imageUrl = convertBaseURL(branch.imageUrl);
    images.push({
      id: branch.id,
      dataUrl,
      imageUrl: branch.imageUrl,
    });
  }

  return (
    <form
      id={`update-form-branches`}
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-put={`/branches/${branch.id}`}
      hx-trigger="submit"
      hx-target={`#table-branches`}
      hx-boost="true"
      hx-swap="outerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Lokasi Outlet</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            value={branch.name}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-address">Alamat Outlet</Label>
          <TextArea id="form-horizontal-text-address" name="address" safe>
            {branch.address}
          </TextArea>
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-googleMapsUrl">
            Link Google Maps
          </Label>
          <Input
            id="form-horizontal-text-googleMapsUrl"
            type="text"
            name="googleMapsUrl"
            value={branch.googleMapsUrl || ""}
            data-omit-if-empty
          />
        </div>

        <div class="mb-4">
          <Label>Jam Operasional Outlet</Label>
          <div class="flex gap-2 items-center uk-child-width-expand mb-1">
            <Label for="form-weekday-start" customClass="max-w-[125px]">
              Senin - Kamis
            </Label>
            <Input
              id="form-weekday-start"
              type="time"
              name="weekdayOpeningTime"
              value={branch.weekdayOpeningTime}
              data-omit-if-empty
            />
            <span class="text-gray-600 text-center max-w-[52px]">hingga</span>
            <Input
              id="form-weekday-end"
              type="time"
              name="weekdayClosingTime"
              value={branch.weekdayClosingTime}
              data-omit-if-empty
            />
          </div>

          <div class="flex gap-2 items-center uk-child-width-expand">
            <Label for="form-weekend-start" customClass="max-w-[125px]">
              Jumat - Minggu
            </Label>
            <Input
              id="form-weekend-start"
              type="time"
              name="weekendOpeningTime"
              value={branch.weekendOpeningTime}
              data-omit-if-empty
            />
            <span class="text-gray-600 text-center max-w-[52px]">hingga</span>
            <Input
              id="form-weekend-end"
              type="time"
              name="weekendClosingTime"
              value={branch.weekendClosingTime}
              data-omit-if-empty
            />
          </div>
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-image-update-branch">Gambar Outlet</Label>
          <div class="uk-form-controls">
            <Dropzone
              images={images}
              id="form-horizontal-image-update-branch"
              name="image"
              accept="image/jpeg, image/png, image/webp"
              placeholder="Gambar harus memiliki resolusi 400 x 400 pixels"
              data-omit-if-empty
            />
          </div>
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[189px]">
            <span id="spinner" class="text-loading-indicator">
              Perbaharui Outlet
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}
