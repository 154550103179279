import { NotFoundError } from "elysia";
import sharp from "sharp";
import { config } from "../config";
import minioClient from "../lib/minio";
import { createBlurhash } from "../utils/blurhash";

type UploadResult = [mediaUrl: string, blurhash: string];
sharp.concurrency(1);

const upload = async ({
  buffer,
  name,
  width = 400,
  height = 400,
}: {
  buffer: Buffer;
  name: string;
  width?: number;
  height?: number;
}): Promise<[string, string]> => {
  const now = new Date();
  const splittedName = name.split(".");
  const fileName = `${now.toISOString()}-${splittedName.shift()}`;
  const isBigImage = width > 1000;

  // Create a separate pipeline for the compressed version
  const resizedBuffer = await sharp(buffer)
    .resize(width, height)
    .rotate()
    .toBuffer();
  const originalImage = sharp(resizedBuffer)
    .avif({ effort: 0, quality: isBigImage ? 80 : 60 })
    .rotate();

  const tasks: Promise<string | Buffer>[] = [
    originalImage.toBuffer(),
    createBlurhash(resizedBuffer),
  ];

  // Add additional task for creating 640px image if width > 1000px
  if (isBigImage) {
    const mobileImage = sharp(buffer)
      .resize(640, 275)
      .avif({ effort: 0, quality: 60 })
      .rotate();
    tasks.push(mobileImage.toBuffer());
  }

  const [compressedBuffer, blurhash, mobileBuffer] = await Promise.all(tasks);

  // Upload the original image
  const url = await minioClient
    .upload(`${fileName}.avif`, compressedBuffer)
    .catch((err: Error) => {
      throw err;
    });

  // If mobileBuffer exists, upload the 640px image
  if (mobileBuffer) {
    await minioClient
      .upload(`${fileName}-640w.avif`, mobileBuffer)
      .catch((err: Error) => {
        throw err;
      });
  }

  return [url, blurhash as string];
};

export const FileStorage = {
  upload: async (
    file: File,
    width?: number,
    height?: number
  ): Promise<UploadResult> => {
    const arrBuf = await file.arrayBuffer();
    const buffer = Buffer.from(arrBuf);

    const [mediaUrl, blurhash] = await upload({
      buffer,
      name: file.name,
      width,
      height,
    });
    return [mediaUrl, blurhash];
  },
  getPresignedUrl: async (name: string): Promise<string> => {
    const decodedName = decodeURIComponent(name);
    if (!(await minioClient.isResourceExist(decodedName))) {
      throw new NotFoundError("File yang diminta tidak ditemukan.");
    }

    try {
      return await minioClient.presignedGetObject(
        decodedName,
        config.minio.presignedUrlDuration
      );
    } catch (e: unknown) {
      throw e;
    }
  },
};
