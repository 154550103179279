import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../../utils/classname";
import { ChevronDown } from "../icons";

interface SelectProps extends JSX.HtmlSelectTag {
  customClass?: string;
}

export function Select({
  children,
  customClass = "",
  ...props
}: PropsWithChildren<SelectProps>): JSX.Element {
  return (
    <div class="uk-form-controls relative">
      <span class="uk-form-icon uk-form-icon-flip">
        <ChevronDown />
      </span>
      <select
        {...props}
        class={cn(
          "uk-select !h-auto py-2 rounded-[10px] text-base text-dark-shade border-[#DAD7D5]",
          customClass
        )}
      >
        {children}
      </select>
    </div>
  );
}
