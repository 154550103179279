import Mailjet from "node-mailjet";
import { config } from "../config";
import { SubmissionCategory } from "./db/contactSubmission";
import { MailConstant } from "../utils/constants";

const transporter = new Mailjet({
  apiKey: config.mailer.mailjet.apiKey,
  apiSecret: config.mailer.mailjet.apiSecret,
});

const constructEmailTextPart = (
  submitterName: string,
  submitterEmail: string,
  message: string,
  category: string
) => {
  return `You just got a form submission!\n\n
  Form\n${category}\n\n
  Submitted Content\n
  Name:${submitterName}\n
  Email:${submitterEmail}\n
  Message: ${message}`;
};

const constructEmailHtmlPart = (
  submitterName: string,
  submitterEmail: string,
  message: string,
  category: string
) => {
  return `<h4>You just got a form submission!</h4>
  <b>Form</b>
  <br />
  ${category}
  <br />
  <br />
  <b>Submitted Content</b>
  <br />
  Name: ${submitterName}<br />
  Email: ${submitterEmail}<br />
  Message: ${message}`;
};

export const sendSubmissionEmail = async (
  category: SubmissionCategory,
  submitterName: string,
  submitterEmail: string,
  message: string
) => {
  return transporter.post("send", { version: "v3.1" }).request({
    Messages: [
      {
        From: {
          Email: config.mailer.mailjet.from,
          Name: config.mailer.mailjet.fromName,
        },
        To: [
          {
            Email: MailConstant.address[category],
            Name: MailConstant.name[category],
          },
        ],
        Subject: MailConstant.subject[category],
        TextPart: constructEmailTextPart(
          submitterName,
          submitterEmail,
          message,
          category
        ),
        HTMLPart: constructEmailHtmlPart(
          submitterName,
          submitterEmail,
          message,
          category
        ),
      },
    ],
  });
};
