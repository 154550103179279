import Database from "bun:sqlite";
import { config } from "../../config";
import { BannersDatabase } from "./banner";
import { BranchesDatabase } from "./branch";
import { CareersDatabase } from "./career";
import { ContactSubmissionsDatabase } from "./contactSubmission";
import { InvestmentsDatabase } from "./investment";
import { ProductsDatabase } from "./product";
import { SessionsDatabase } from "./sessions";
import { UsersDatabase } from "./users";

export const db = new Database(config.db.sqlitePath, { strict: true });
db.run("PRAGMA foreign_keys = ON");

export interface DatabaseModels {
  branches: BranchesDatabase;
  users: UsersDatabase;
  sessions: SessionsDatabase;
  banners: BannersDatabase;
  products: ProductsDatabase;
  careers: CareersDatabase;
  investments: InvestmentsDatabase;
  contactSubmissions: ContactSubmissionsDatabase;
}

export const databaseModels: DatabaseModels = {
  branches: new BranchesDatabase(db),
  users: new UsersDatabase(db),
  sessions: new SessionsDatabase(db),
  banners: new BannersDatabase(db),
  products: new ProductsDatabase(db),
  careers: new CareersDatabase(db),
  investments: new InvestmentsDatabase(db),
  contactSubmissions: new ContactSubmissionsDatabase(db),
};
