import { Html } from "@elysiajs/html";

export function BannerSwitcher(): JSX.Element {
  return (
    <ul
      class="uk-tab mb-6 !py-2 !px-4 gap-4"
      uk-switcher="animation: uk-animation-fade"
    >
      <li>
        <a
          class="cursor-pointer"
          hx-get="/banners/sortable?type=main"
          hx-target="#banners-main"
          hx-swap="innerHTML"
          hx-trigger="click"
          hx-boost="true"
        >
          Halaman Utama
        </a>
      </li>
      <li>
        <a
          class="cursor-pointer"
          hx-get="/banners/sortable?type=menu"
          hx-target="#banners-menu"
          hx-swap="innerHTML"
          hx-trigger="click"
          hx-boost="true"
        >
          Halaman Menu
        </a>
      </li>
      <li>
        <a
          class="cursor-pointer"
          hx-get="/banners/sortable?type=branch"
          hx-target="#banners-branch"
          hx-swap="innerHTML"
          hx-trigger="click"
          hx-boost="true"
        >
          Halaman Lokasi
        </a>
      </li>
      <li>
        <a
          class="cursor-pointer"
          hx-get="/banners/sortable?type=investment"
          hx-target="#banners-investment"
          hx-swap="innerHTML"
          hx-trigger="click"
          hx-boost="true"
        >
          Halaman Investasi
        </a>
      </li>
      <li>
        <a
          class="cursor-pointer"
          hx-get="/banners/sortable?type=career"
          hx-target="#banners-career"
          hx-swap="innerHTML"
          hx-trigger="click"
          hx-boost="true"
        >
          Halaman Karir
        </a>
      </li>
    </ul>
  );
}
