import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { Button } from "./button";
import { Image } from "./image";
import { Close, Plus } from "./icons";
import { cn } from "../utils/classname";

export type DropzoneImage = {
  id: string;
  imageUrl: string;
  dataUrl?: string;
};

interface DropzoneProps extends JSX.HtmlInputTag {
  "data-omit-if-empty"?: boolean;
  images?: DropzoneImage[];
  deletedImagesName?: string;
  deletedImagesId?: string;
}

export function Dropzone({
  images,
  children,
  placeholder,
  deletedImagesId = "deleted-images",
  deletedImagesName = "deletedImages",
  ...props
}: PropsWithChildren<DropzoneProps>): JSX.Element {
  const isMultipleImages = images && images.length > 0 && props.multiple;
  const isSingleImages = images && images.length > 0 && !props.multiple;

  return (
    <>
      <div
        {...(!isMultipleImages ? { "uk-form-custom": true } : {})}
        class={cn(
          { "uk-placeholder": !isMultipleImages },
          "uk-form-controls uk-text-center block border-none min-h-[250px] mb-0"
        )}
      >
        <div
          style={{ display: isMultipleImages ? "none" : "flex" }}
          class="absolute inset-0 flex flex-col gap-[5px] justify-center items-center border border-[#DAD7D5] border-dashed rounded-[10px]"
        >
          <span class="text-sm text-dark-grey" safe>
            {placeholder}
          </span>
          <Button
            id="dropzone-add-btn-single"
            data-input-id={`dropzone-input-${props.id}`}
            size="md"
            type="button"
            customClass="z-10 bg-transparent text-primary-foreground border border-[#DAD7D5] flex items-center gap-2.5 hover:bg-[#4C5424] hover:text-white hover:border-transparent"
          >
            <Plus />
            Tambah Gambar
          </Button>
        </div>

        {/* preview image for single image */}
        <div
          class="dropzone-overlay-image absolute inset-0 z-10 bg-[#FCFCFC] rounded-[10px] border-solid border border-[#DAD7D5]"
          style={{ display: isSingleImages ? "block" : "none" }}
        >
          {isSingleImages ? (
            <Image
              url={images[0].imageUrl || ""}
              dataUrl={images[0].dataUrl || ""}
              width={100000}
              height={247}
              dimensionType="fluid"
              alt="Preview"
              customClass="object-contain rounded-[10px] my-[1px]"
            />
          ) : (
            ""
          )}
          <button
            id={`dropzone-del-btn-${images?.[0]?.id}`}
            type="button"
            class="absolute top-3 right-5 w-6 h-6 flex justify-center items-center rounded-full bg-[#DAD7D5]"
          >
            <Close width="12" height="12" />
          </button>
        </div>

        <input
          {...props}
          name={props.multiple ? "" : props.name}
          id={`dropzone-input-${props.id}`}
          type="file"
          style={{ display: props.multiple ? "none" : "block" }}
        />

        {props.multiple ? (
          <input
            {...props}
            name={props.name}
            id={`dropzone-real-input-${props.id}`}
            type="file"
            style={{ display: "none" }}
          />
        ) : (
          ""
        )}

        {/* preview image for multiple images */}
        <div id="dropzone-preview-container" class="flex flex-wrap gap-2.5">
          {isMultipleImages && (
            <>
              {images.map((image) => (
                <div id={image.id} class="relative w-[200px] h-[200px]">
                  <Image
                    url={image.imageUrl}
                    dataUrl={image.dataUrl || ""}
                    width={200}
                    height={200}
                    alt="Preview"
                    customClass="object-contain rounded-[10px]"
                  />
                  <button
                    id={`dropzone-del-btn-${image?.id}`}
                    data-image-id={image.id}
                    data-input-id={`dropzone-input-${props.id}`}
                    data-delete-id={deletedImagesId}
                    type="button"
                    class="absolute top-[3px] right-[4px] p-[6px] rounded-full bg-[#DAD7D5]"
                  >
                    <Close width="12" height="12" />
                  </button>
                </div>
              ))}
            </>
          )}
        </div>

        {/* button add image for multiple images */}
        <Button
          id="dropzone-add-btn-multiple"
          data-input-id={`dropzone-input-${props.id}`}
          size="md"
          type="button"
          style={{ display: isMultipleImages ? "flex" : "none" }}
          customClass="mt-1 z-10 bg-transparent text-primary-foreground border border-[#DAD7D5] flex items-center gap-2.5 hover:bg-[#4C5424] hover:text-white hover:border-transparent"
        >
          <Plus />
          Tambah Gambar
        </Button>
      </div>
      <p class="uk-form-help uk-text-danger" id={`${props.name}-error`}></p>

      {/* input text for deleted images */}
      {props.multiple ? (
        <input
          id={deletedImagesId}
          name={deletedImagesName}
          type="hidden"
          value=""
          data-omit-if-empty
        />
      ) : (
        ""
      )}
    </>
  );
}
