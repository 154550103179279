import { Database } from "bun:sqlite";
import { ulid } from "ulid";
import { Logger } from "../logger";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { BaseDatabase, Paginated } from "./root";

export interface Product {
  id: string;
  name: string;
  category: "juice" | "dessert" | "food";
  price: number;
  secondaryPrice: number;
  imageUrl: string;
  blurhash: string;
  isHighlighted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class ProductsDatabase extends BaseDatabase {
  protected db: Database;

  constructor(_db: Database) {
    super(_db);
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("products table initialized"))
      .catch(() => Logger.error("products table failed to initialize"));
  }

  // get by id
  getById(id: string): Product {
    const result = this.db
      .query(`SELECT * FROM products WHERE id = ?`)
      .get(id) as object;
    return toCamelCase(result) as Product;
  }

  // Get products paginated with optional name search
  getPaginated(
    page: number,
    pageSize: number,
    name?: string
  ): Paginated<Product> {
    let results: object[];
    let totalRows: { total: number };

    if (name && name.trim().length >= 3) {
      // Perform full-text search if name is provided
      const processedQuery = name
        .trim()
        .replace(/[^a-zA-Z]/g, "")
        .split(/\s+/)
        .map((word) => `${word}*`)
        .join(" ");

      results = this.db
        .query(
          `
            SELECT c.* 
            FROM products c
            INNER JOIN products_fts fts ON c.rowid = fts.rowid
            WHERE products_fts MATCH ?
            ORDER BY rank
            LIMIT ? OFFSET ?
          `
        )
        .all(processedQuery, pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(
          `
            SELECT COUNT(*) as total 
            FROM products c
            INNER JOIN products_fts fts ON c.rowid = fts.rowid
            WHERE products_fts MATCH ?
          `
        )
        .get(processedQuery) as { total: number };
    } else {
      // Regular pagination if no name is provided
      results = this.db
        .query(
          `SELECT * FROM products ORDER BY updated_at DESC LIMIT ? OFFSET ?`
        )
        .all(pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(`SELECT COUNT(*) as total FROM products`)
        .get() as { total: number };
    }

    const products = results.map(toCamelCase) as Product[];
    const metadata = constructPaginationMetadata(totalRows, page, pageSize);

    return { data: products, metadata };
  }

  // Create a new product
  create(
    name: string,
    category: "juice" | "dessert" | "food",
    price: number,
    secondaryPrice: number,
    imageUrl: string,
    blurhash: string,
    isHighlighted: boolean = false
  ) {
    this.db
      .query(
        `INSERT INTO products (id, name, category, price, secondary_price, image_url, blurhash, is_highlighted, created_at, updated_at) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?) RETURNING id`
      )
      .get(
        ulid(),
        name,
        category,
        price,
        secondaryPrice,
        imageUrl,
        blurhash,
        isHighlighted,
        new Date().toISOString(),
        new Date().toISOString()
      );
  }

  // Update a product
  update(
    id: string,
    name?: string,
    category?: "juice" | "dessert" | "food",
    price?: number,
    secondaryPrice?: number,
    imageUrl?: string,
    blurhash?: string,
    isHighlighted?: boolean
  ) {
    const fieldsToUpdate: string[] = [];
    const values: (string | number | boolean | null)[] = [];

    if (name !== undefined) {
      fieldsToUpdate.push("name = ?");
      values.push(name);
    }
    if (category !== undefined) {
      fieldsToUpdate.push("category = ?");
      values.push(category);
    }
    if (price !== undefined) {
      fieldsToUpdate.push("price = ?");
      values.push(price);
    }
    if (secondaryPrice !== undefined) {
      fieldsToUpdate.push("secondary_price = ?");
      values.push(secondaryPrice);
    }
    if (imageUrl !== undefined) {
      fieldsToUpdate.push("image_url = ?");
      values.push(imageUrl);
    }
    if (blurhash !== undefined) {
      fieldsToUpdate.push("blurhash = ?");
      values.push(blurhash);
    }
    if (isHighlighted !== undefined) {
      fieldsToUpdate.push("is_highlighted = ?");
      values.push(isHighlighted);
    }

    // Always update the updated_at field
    fieldsToUpdate.push("updated_at = ?");
    values.push(new Date().toISOString());

    if (fieldsToUpdate.length === 0) {
      return;
    }

    values.push(id);

    const query = `UPDATE products SET ${fieldsToUpdate.join(
      ", "
    )} WHERE id = ?`;
    this.db.run(query, values);
  }

  // Delete a product
  delete(id: string) {
    this.db.run(`DELETE FROM products WHERE id = '${id}'`);
  }

  // Initialize the database
  async init() {
    this.db.run(
      `CREATE TABLE IF NOT EXISTS products (
        id TEXT NOT NULL PRIMARY KEY,
        name TEXT NOT NULL,
        category TEXT NOT NULL,
        price INTEGER NOT NULL,
        secondary_price INTEGER DEFAULT 0,
        image_url TEXT NOT NULL,
        blurhash TEXT NOT NULL,
        is_highlighted BOOLEAN NOT NULL DEFAULT FALSE,
        created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
        updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
      );`
    );

    // Create FTS5 virtual table with trigram tokenizer
    this.db.run(
      `CREATE VIRTUAL TABLE IF NOT EXISTS products_fts USING fts5(
        name,
        content='products',
        content_rowid='rowid',
        tokenize='trigram'
      );`
    );

    // Create triggers for synchronization
    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS products_ai AFTER INSERT ON products BEGIN
        INSERT INTO products_fts(rowid, name)
        VALUES (new.rowid, new.name);
      END;
    `);

    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS products_ad AFTER DELETE ON products BEGIN
        INSERT INTO products_fts(products_fts, rowid, name)
        VALUES('delete', old.rowid, old.name);
      END;
    `);

    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS products_au AFTER UPDATE ON products BEGIN
        INSERT INTO products_fts(products_fts, rowid, name)
        VALUES('delete', old.rowid, old.name);
        INSERT INTO products_fts(rowid, name)
        VALUES (new.rowid, new.name);
      END;
    `);
  }
}
