import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../../utils/classname";

interface LabelProps extends JSX.HtmlLabelTag {
  customClass?: string;
}

export function Label({
  children,
  customClass = "",
  ...props
}: PropsWithChildren<LabelProps>): JSX.Element {
  return (
    <label
      {...props}
      class={cn("block text-base text-dark-shade mb-1", customClass)}
    >
      {children}
    </label>
  );
}
