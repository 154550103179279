import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { Navbar } from "../components/navbar";

export function Layout({
  currentPath,
  children: safeChildren,
}: PropsWithChildren<{
  currentPath: string;
  children?: JSX.Element;
}>) {
  return (
    <div class="flex justify-center ">
      <div class="absolute z-0 inset-0 flex">
        <div class="bg-[#FCFCFC] flex-1"></div>
        <div class="bg-secondary-foreground flex-1"></div>
      </div>
      <div class="relative flex flex-col h-full min-h-screen w-full max-w-[2560px]">
        <Navbar currentPath={currentPath} />
        <div class="pl-[236px] h-full min-h-screen bg-secondary-foreground">
          {safeChildren}
        </div>
      </div>
    </div>
  );
}
