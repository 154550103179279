import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { CreateInvestmentForm } from "../components/investments/forms/create";
import { UpdateInvestmentForm } from "../components/investments/forms/update";
import { Table } from "../components/table";
import { databaseModels } from "../lib/db";
import { InvestmentImage } from "../lib/db/investment";
import { PaginationMetadata } from "../lib/db/root";
import { authPlugin } from "../lib/auth/plugin";
import { checkMutationAttempts } from "../lib/rateLimiter";
import { InvestmentPage } from "../pages/investment";
import { FileStorage } from "../services/fileStorage";
import { InvestmentService } from "../services/investment";
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
  INVESTMENT_TABLE_HEADER,
} from "../utils/constants";
import { NotificationPayload } from "../utils/notification";
import {
  createInvestmentValidation,
  deleteInvestmentValidation,
  updateInvestmentValidation,
} from "../validation/investment";
import { tableValidation } from "../validation/table";

const getPaginated = (
  data: string[][],
  metadata: PaginationMetadata
): JSX.Element => {
  return (
    <Table
      id="table-investments"
      type="investments"
      headings={INVESTMENT_TABLE_HEADER}
      data={data}
      metadata={metadata}
    />
  );
};

const constructImages = async (
  newImages?: File[]
): Promise<InvestmentImage[]> => {
  const investmentImages: InvestmentImage[] = [];

  for (const image of newImages || []) {
    const [imageUrl, blurhash] = await FileStorage.upload(image, 1200, 500);
    investmentImages.push({ imageUrl, blurhash });
  }

  return investmentImages;
};

export const investmentController = new Elysia().group("/investments", (app) =>
  app
    .decorate("db", databaseModels)
    .derive(authPlugin)
    .get("/", ({ db }): JSX.Element => {
      const title = "Jus Kode - Investments";
      const { data, metadata } = InvestmentService.getPaginated(db);
      return (
        <InvestmentPage title={title}>
          {getPaginated(data, metadata)}
        </InvestmentPage>
      );
    })
    .get(
      "/filter",
      ({ db, query }): JSX.Element => {
        const page =
          query.page < DEFAULT_CURRENT_PAGE ? DEFAULT_CURRENT_PAGE : query.page;
        const pageSize = DEFAULT_PAGE_SIZE;
        const name = query.name;

        const { data, metadata } = InvestmentService.getPaginated(
          db,
          page,
          pageSize,
          name
        );
        return getPaginated(data, metadata);
      },
      { ...tableValidation }
    )
    .get("/create-form", ({}): JSX.Element => {
      return <CreateInvestmentForm />;
    })
    .get("/:id/update-form", ({ params, db }): JSX.Element => {
      const id = params.id;
      const investment = db.investments.getById(id);

      if (!investment) {
        return <div>Investment not found</div>;
      }

      return <UpdateInvestmentForm investment={investment} />;
    })
    .post(
      "/",
      async ({ body, db, request, server, set }): Promise<JSX.Element> => {
        await checkMutationAttempts(request, server, "create-investment");
        const investmentImages = await constructImages(body.images);

        const { data, metadata } = InvestmentService.create(
          {
            ...body,
            isActive: body.isActive === "true",
            investmentImages,
            totalInvestment: parseInt(body.totalInvestment),
            totalInvestmentCollected: parseInt(body.totalInvestmentCollected),
            googleMapsUrl: body.googleMapsUrl
              ? new URL(body.googleMapsUrl)
              : undefined,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Investasi telah berhasil ditambahkan.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...createInvestmentValidation }
    )
    .put(
      "/:id",
      async ({ db, params, body, set }): Promise<JSX.Element> => {
        const id = params.id;
        const investmentImages = await constructImages(body.images);

        const { data, metadata } = InvestmentService.update(
          id,
          {
            ...body,
            isActive: body.isActive ? body.isActive === "true" : undefined,
            investmentImages,
            totalInvestment: body.totalInvestment
              ? parseInt(body.totalInvestment)
              : undefined,
            totalInvestmentCollected: body.totalInvestmentCollected
              ? parseInt(body.totalInvestmentCollected)
              : undefined,
            googleMapsUrl: body.googleMapsUrl
              ? new URL(body.googleMapsUrl)
              : undefined,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Investasi telah berhasil diperbaharui.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      {
        ...updateInvestmentValidation,
      }
    )
    .delete(
      "/:id",
      ({ params, db, set }): JSX.Element => {
        const id = params.id;
        const { data, metadata } = InvestmentService.delete(id, db);

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Investasi telah berhasil dihapus.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...deleteInvestmentValidation }
    )
);
