import { Database } from "bun:sqlite";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { BaseDatabase, Paginated } from "./root";
import { Logger } from "../logger";
import { ulid } from "ulid";

export interface Investment {
  id: string;
  branchName: string;
  totalInvestment: number;
  totalInvestmentCollected: number;
  location: string;
  description: string;
  isActive: boolean;
  images: InvestmentImage[];
  createdAt: Date;
  updatedAt: Date;
  googleMapsUrl?: string;
}

export interface InvestmentImage {
  id?: string;
  investmentId?: string;
  imageUrl: string;
  blurhash: string;
}

export class InvestmentsDatabase extends BaseDatabase {
  protected db: Database;

  constructor(_db: Database) {
    super(_db);
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("investments table initialized"))
      .catch(() => Logger.error("investments table failed to initialize"));
  }

  // get by id
  getById(id: string): Investment {
    const result = this.db
      .query(`SELECT * FROM investments WHERE id = ?`)
      .get(id) as object;
    const investment = toCamelCase(result) as Investment;
    investment.images = [];

    const images = this.db
      .query(`SELECT * FROM investment_images WHERE investment_id = ?`)
      .all(id) as object[];

    for (const image of images) {
      investment.images.push(toCamelCase(image) as InvestmentImage);
    }

    return investment;
  }

  // Get investments paginated with optional name search
  getPaginated(
    page: number,
    pageSize: number,
    name?: string
  ): Paginated<Investment> {
    let results: object[];
    let totalRows: { total: number };

    if (name && name.trim().length >= 3) {
      // Perform full-text search if name is provided
      const processedQuery = name
        .trim()
        .replace(/[^a-zA-Z]/g, "")
        .split(/\s+/)
        .map((word) => `${word}*`)
        .join(" ");

      results = this.db
        .query(
          `
            SELECT c.* 
            FROM investments c
            INNER JOIN investments_fts fts ON c.rowid = fts.rowid
            WHERE investments_fts MATCH ?
            ORDER BY rank
            LIMIT ? OFFSET ?
          `
        )
        .all(processedQuery, pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(
          `
            SELECT COUNT(*) as total 
            FROM investments c
            INNER JOIN investments_fts fts ON c.rowid = fts.rowid
            WHERE investments_fts MATCH ?
          `
        )
        .get(processedQuery) as { total: number };
    } else {
      // Regular pagination if no name is provided
      results = this.db
        .query(
          `SELECT * FROM investments ORDER BY updated_at DESC LIMIT ? OFFSET ?`
        )
        .all(pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(`SELECT COUNT(*) as total FROM investments`)
        .get() as { total: number };
    }

    const investments = results.map(toCamelCase) as Investment[];
    const metadata = constructPaginationMetadata(totalRows, page, pageSize);

    return { data: investments, metadata };
  }

  // Create a new investment
  create(
    branchName: string,
    totalInvestment: number,
    totalInvestmentCollected: number,
    location: string,
    description: string,
    investmentImages: InvestmentImage[],
    isActive: boolean = false,
    mapsUrl?: URL
  ): string {
    const id = ulid();
    this.db
      .query(
        `INSERT INTO investments (id, branch_name, total_investment, total_investment_collected, location, google_maps_url, description, is_active, created_at, updated_at) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`
      )
      .run(
        id,
        branchName,
        totalInvestment,
        totalInvestmentCollected,
        location,
        mapsUrl?.toString() || null,
        description,
        isActive,
        new Date().toISOString(),
        new Date().toISOString()
      );

    for (const image of investmentImages) {
      const imageId = ulid();
      this.db
        .query(
          `INSERT INTO investment_images (id, investment_id, image_url, blurhash) VALUES (?, ?, ?, ?)`
        )
        .run(imageId, id, image.imageUrl, image.blurhash);
    }

    return id;
  }

  // Update a investment
  update(
    id: string,
    investmentImages: { id?: string; imageUrl?: string; blurhash?: string }[],
    branchName?: string,
    totalInvestment?: number,
    totalInvestmentCollected?: number,
    location?: string,
    description?: string,
    isActive?: boolean,
    mapsUrl?: URL
  ) {
    const fieldsToUpdate: string[] = [];
    const values: (string | number | boolean | null)[] = [];

    if (branchName !== undefined) {
      fieldsToUpdate.push("branch_name = ?");
      values.push(branchName);
    }
    if (totalInvestment !== undefined) {
      fieldsToUpdate.push("total_investment = ?");
      values.push(totalInvestment);
    }
    if (totalInvestmentCollected !== undefined) {
      fieldsToUpdate.push("total_investment_collected = ?");
      values.push(totalInvestmentCollected);
    }
    if (location !== undefined) {
      fieldsToUpdate.push("location = ?");
      values.push(location);
    }
    if (mapsUrl !== undefined && mapsUrl !== null) {
      fieldsToUpdate.push("google_maps_url = ?");
      values.push(mapsUrl.toString());
    }
    if (description !== undefined) {
      fieldsToUpdate.push("description = ?");
      values.push(description);
    }
    if (isActive !== undefined) {
      fieldsToUpdate.push("is_active = ?");
      values.push(isActive);
    }

    // Always update the updated_at field
    fieldsToUpdate.push("updated_at = ?");
    values.push(new Date().toISOString());

    if (fieldsToUpdate.length > 0) {
      values.push(id);
      const query = `UPDATE investments SET ${fieldsToUpdate.join(
        ", "
      )} WHERE id = ?`;
      this.db.run(query, values);
    }

    for (const image of investmentImages) {
      if (image.imageUrl && image.blurhash) {
        const imageId = ulid();
        this.db
          .query(
            `INSERT INTO investment_images (id, investment_id, image_url, blurhash) VALUES (?, ?, ?, ?)`
          )
          .run(imageId, id, image.imageUrl, image.blurhash);
      }
    }
  }

  getInvestmentImages(investmentId: string): string[] {
    const images = this.db
      .query(`SELECT id FROM investment_images WHERE investment_id = ?`)
      .all(investmentId) as { id: string }[];
    return images.map((image) => image.id);
  }

  deleteInvestmentImages(investmentImagesIds: string[]) {
    this.db
      .query(
        `DELETE FROM investment_images WHERE id IN (${investmentImagesIds
          .map(() => "?")
          .join(",")})`
      )
      .run(...investmentImagesIds);
  }

  // Delete a investment
  delete(id: string) {
    this.db.run(`DELETE FROM investments WHERE id = '${id}'`);
  }

  // Initialize the database
  async init() {
    this.db.run(
      `
      CREATE TABLE IF NOT EXISTS investments (
        id TEXT NOT NULL PRIMARY KEY,
        branch_name TEXT NOT NULL,
        total_investment INTEGER DEFAULT 0 NOT NULL,
        total_investment_collected INTEGER DEFAULT 0 NOT NULL,
        location TEXT NOT NULL,
        description TEXT NOT NULL,
        is_active BOOLEAN NOT NULL DEFAULT FALSE,
        created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
        updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
      );
      
      CREATE TABLE IF NOT EXISTS investment_images (
        id TEXT NOT NULL PRIMARY KEY,
        investment_id TEXT REFERENCES investments(id) ON DELETE CASCADE ON UPDATE CASCADE,
        image_url TEXT NOT NULL,
        blurhash TEXT NOT NULL
      );`
    );

    // Create FTS5 virtual table with trigram tokenizer
    this.db.run(
      `CREATE VIRTUAL TABLE IF NOT EXISTS investments_fts USING fts5(
        branch_name,
        content='investments',
        content_rowid='rowid',
        tokenize='trigram'
      );`
    );

    // Create triggers for synchronization
    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS investments_ai AFTER INSERT ON investments BEGIN
        INSERT INTO investments_fts(rowid, branch_name)
        VALUES (new.rowid, new.branch_name);
      END;
    `);

    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS investments_ad AFTER DELETE ON investments BEGIN
        INSERT INTO investments_fts(investments_fts, rowid, branch_name)
        VALUES('delete', old.rowid, old.branch_name);
      END;
    `);

    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS investments_au AFTER UPDATE ON investments BEGIN
        INSERT INTO investments_fts(investments_fts, rowid, branch_name)
        VALUES('delete', old.rowid, old.branch_name);
        INSERT INTO investments_fts(rowid, branch_name)
        VALUES (new.rowid, new.branch_name);
      END;
    `);

    // check if column `google_maps_url` as TEXT exists in the table
    // if not, add the column
    const googleMapsUrlColumnExists = this.db
      .query(
        `SELECT name FROM pragma_table_info('investments') WHERE name = 'google_maps_url'`
      )
      .get();

    if (!googleMapsUrlColumnExists) {
      this.db.run(`ALTER TABLE investments ADD COLUMN google_maps_url TEXT`);
    }
  }
}
