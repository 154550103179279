import { t } from "elysia";

export const createCareerValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.String({
      error: "Nama diperlukan.",
      minLength: 1,
    }),
    division: t.String({
      error: "Divisi diperlukan.",
      minLength: 1,
    }),
    location: t.String({
      error: "Lokasi diperlukan.",
      minLength: 1,
    }),
    requirements: t.String({
      error: "Persyaratan diperlukan.",
      minLength: 1,
    }),
    email: t.String({
      error: "Email diperlukan dan harus valid.",
      format: "email",
      minLength: 1,
    }),
    isActive: t.String({
      error: "Status aktif harus berupa boolean.",
      pattern: "^(true|false)$",
      minLength: 1,
    }),
  }),
};

export const updateCareerValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.Optional(
      t.String({
        error: "Nama harus berupa string.",
        minLength: 1,
      })
    ),
    division: t.Optional(
      t.String({
        error: "Divisi harus berupa string.",
        minLength: 1,
      })
    ),
    location: t.Optional(
      t.String({
        error: "Lokasi harus berupa string.",
        minLength: 1,
      })
    ),
    requirements: t.Optional(
      t.String({
        error: "Persyaratan harus berupa string.",
        minLength: 1,
      })
    ),
    email: t.Optional(
      t.String({
        error: "Email harus valid.",
        format: "email",
        minLength: 1,
      })
    ),
    isActive: t.Optional(
      t.String({
        error: "Status aktif harus berupa boolean.",
        pattern: "^(true|false)$",
        minLength: 1,
      })
    ),
  }),
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};

export const deleteCareerValidation = {
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};
