import * as crypto from "crypto";

export enum AuthorityEnum {
  VIEW_ROLE = "VIEW_ROLE",
  CREATE_ROLE = "CREATE_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",

  VIEW_USER = "VIEW_USER",
  CREATE_USER = "CREATE_USER",
  UPDATE_USER = "UPDATE_USER",

  VIEW_PRODUCT = "VIEW_PRODUCT",
  CREATE_PRODUCT = "CREATE_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",

  VIEW_SCROLLING_BANNER = "VIEW_SCROLLING_BANNER",
  CREATE_SCROLLING_BANNER = "CREATE_SCROLLING_BANNER",
  UPDATE_SCROLLING_BANNER = "UPDATE_SCROLLING_BANNER",
  DELETE_SCROLLING_BANNER = "DELETE_SCROLLING_BANNER",

  VIEW_BRANCH = "VIEW_BRANCH",
  CREATE_BRANCH = "CREATE_BRANCH",
  UPDATE_BRANCH = "UPDATE_BRANCH",
}

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  TOO_MANY_REQUESTS = 429,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum Environment {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

export enum LoggerOutput {
  FILE = "file",
  STDOUT = "stdout",
}

export const MimeType = {
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  PNG: "image/png",
  HEIC: "image/heic",
  PDF: "application/pdf",
  CSV: "text/csv",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS: "application/vnd.ms-excel",
};

export const Extension = {
  JPEG: "jpeg",
  JPG: "jpg",
  PNG: "png",
  HEIC: "heic",
  PDF: "pdf",
  CSV: "csv",
  XLSX: "xlsx",
  XLS: "xls",
};

export const HeicBrands = [
  "heic",
  "heix",
  "mif1",
  "msf1",
  "hevc",
  "hevs",
  "avif", // Some HEIC files might use AVIF
];

export type Metadata = {
  ext: string;
  mime: string;
};

export const MagicNumbers: {
  [key: string]: Metadata;
} = {
  FFD8FF: { ext: Extension.JPEG, mime: MimeType.JPEG },
  "89504E470D0A1A0A": { ext: Extension.PNG, mime: MimeType.PNG },
  D0CF11E0A1B11AE1: { ext: Extension.XLS, mime: MimeType.XLS },
  "504B0304": {
    ext: Extension.XLSX,
    mime: MimeType.XLSX,
  },
};

export const DEFAULT_PAGE_SIZE = 5;
export const MAX_PAGE_SIZE = 50;
export const DEFAULT_CURRENT_PAGE = 0;

export const SALT_ROUNDS = 10;

export const SUPER_ADMIN_ROLE_ID = 1;
export const ADMIN_ROLE_ID = 2;

export const SUPER_ADMIN_ROLE = "SUPER_ADMIN";
export const ADMIN_ROLE = "ADMIN";

export const BRANCH_TABLE_HEADER = [
  "Lokasi Outlet",
  "Alamat",
  "Jam Operasional",
  "Action",
];

export const PRODUCT_TABLE_HEADER = [
  "Nama Produk",
  "Kategori",
  "Harga Produk",
  "Action",
];

export const CAREER_TABLE_HEADER = [
  "Nama Posisi",
  "Nama Divisi",
  "Lokasi",
  "Action",
];

export const INVESTMENT_TABLE_HEADER = [
  "Nama Outlet",
  "Total Investasi",
  "Total Investasi Terkumpul",
  "Lokasi Outlet",
  "Action",
];

export const constructSecurityHeaders = (): Record<string, string> => {
  const nonce = generateNonce();
  return {
    // Security headers
    "strict-transport-security": "max-age=31536000; includeSubDomains; preload",
    "x-content-type-options": "nosniff",
    "x-frame-options": "DENY",

    // Cache control
    "cache-control": "no-store, max-age=0",
    vary: "HX-Request, Content-Type, Accept-Encoding",

    // Referrer Policy
    "referrer-policy": "no-referrer",

    // Permissions Policy
    "permissions-policy": "geolocation=(), microphone=(), camera=()",

    // Content headers
    "content-type": "text/html; charset=utf-8",

    // CSP header for enhanced security
    "content-security-policy": `default-src 'self'; \
       img-src 'self' data: media.juskode.id; \
       media-src 'self' www.dropbox.com *.dl.dropboxusercontent.com; \
       script-src 'self' 'nonce-${nonce}';  \
       style-src 'self' 'unsafe-inline'; \
       object-src 'none'; \
       base-uri 'self'; \
       frame-ancestors 'none';`,
  };
};

export const MailConstant = {
  address: {
    partnership: "juskodepurchasing@gmail.com",
    collaboration: "marketing.juskode@gmail.com",
  },
  name: {
    partnership: "Jus Kode - Partnership",
    collaboration: "Jus Kode - Collaboration",
  },
  subject: {
    partnership: "[Partnership] New Form Submission From Jus Kode Website",
    collaboration: "[Collaboration] New Form Submission From Jus Kode Website",
  },
};

export function generateNonce() {
  return crypto.randomBytes(16).toString("base64");
}
