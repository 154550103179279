import { Database } from "bun:sqlite";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { BaseDatabase, Paginated } from "./root";
import { Logger } from "../logger";
import { ulid } from "ulid";

export interface Career {
  id: string;
  name: string;
  division: string;
  location: string;
  requirements: string;
  email: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class CareersDatabase extends BaseDatabase {
  protected db: Database;

  constructor(_db: Database) {
    super(_db);
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("careers table initialized"))
      .catch(() => Logger.error("careers table failed to initialize"));
  }

  // get by id
  getById(id: string): Career {
    const result = this.db
      .query(`SELECT * FROM careers WHERE id = ?`)
      .get(id) as object;
    return toCamelCase(result) as Career;
  }

  // Get careers paginated with optional name search
  getPaginated(
    page: number,
    pageSize: number,
    name?: string
  ): Paginated<Career> {
    let results: object[];
    let totalRows: { total: number };

    if (name && name.trim().length >= 3) {
      // Perform full-text search if name is provided
      const processedQuery = name
        .trim()
        .replace(/[^a-zA-Z]/g, "")
        .split(/\s+/)
        .map((word) => `${word}*`)
        .join(" ");

      results = this.db
        .query(
          `
            SELECT c.* 
            FROM careers c
            INNER JOIN careers_fts fts ON c.rowid = fts.rowid
            WHERE careers_fts MATCH ?
            ORDER BY rank
            LIMIT ? OFFSET ?
          `
        )
        .all(processedQuery, pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(
          `
            SELECT COUNT(*) as total 
            FROM careers c
            INNER JOIN careers_fts fts ON c.rowid = fts.rowid
            WHERE careers_fts MATCH ?
          `
        )
        .get(processedQuery) as { total: number };
    } else {
      // Regular pagination if no name is provided
      results = this.db
        .query(
          `SELECT * FROM careers ORDER BY updated_at DESC LIMIT ? OFFSET ?`
        )
        .all(pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(`SELECT COUNT(*) as total FROM careers`)
        .get() as { total: number };
    }

    const careers = results.map(toCamelCase) as Career[];
    const metadata = constructPaginationMetadata(totalRows, page, pageSize);

    return { data: careers, metadata };
  }

  // Create a new career
  create(
    name: string,
    division: string,
    location: string,
    requirements: string,
    email: string,
    isActive: boolean = false
  ) {
    this.db
      .query(
        `INSERT INTO careers (id, name, division, location, requirements, email, is_active, created_at, updated_at) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?) RETURNING id`
      )
      .get(
        ulid(),
        name,
        division,
        location,
        requirements,
        email,
        isActive,
        new Date().toISOString(),
        new Date().toISOString()
      );
  }

  // Update a career
  update(
    id: string,
    name?: string,
    division?: string,
    location?: string,
    requirements?: string,
    email?: string,
    isActive?: boolean
  ) {
    const fieldsToUpdate: string[] = [];
    const values: (string | number | boolean | null)[] = [];

    if (name !== undefined) {
      fieldsToUpdate.push("name = ?");
      values.push(name);
    }
    if (division !== undefined) {
      fieldsToUpdate.push("division = ?");
      values.push(division);
    }
    if (location !== undefined) {
      fieldsToUpdate.push("location = ?");
      values.push(location);
    }
    if (requirements !== undefined) {
      fieldsToUpdate.push("requirements = ?");
      values.push(requirements);
    }
    if (email !== undefined) {
      fieldsToUpdate.push("email = ?");
      values.push(email);
    }
    if (isActive !== undefined) {
      fieldsToUpdate.push("is_active = ?");
      values.push(isActive);
    }

    // Always update the updated_at field
    fieldsToUpdate.push("updated_at = ?");
    values.push(new Date().toISOString());

    if (fieldsToUpdate.length === 0) {
      return;
    }

    values.push(id);

    const query = `UPDATE careers SET ${fieldsToUpdate.join(
      ", "
    )} WHERE id = ?`;
    this.db.run(query, values);
  }

  // Delete a career
  delete(id: string) {
    this.db.run(`DELETE FROM careers WHERE id = '${id}'`);
  }

  // Initialize the database
  async init() {
    // Create the main careers table
    this.db.run(
      `CREATE TABLE IF NOT EXISTS careers (
        id TEXT NOT NULL PRIMARY KEY,
        name TEXT NOT NULL,
        division TEXT NOT NULL,
        location TEXT NOT NULL,
        requirements TEXT NOT NULL,
        email TEXT NOT NULL,
        is_active BOOLEAN NOT NULL DEFAULT FALSE,
        created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
        updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
      );`
    );

    // Create FTS5 virtual table with trigram tokenizer
    this.db.run(
      `CREATE VIRTUAL TABLE IF NOT EXISTS careers_fts USING fts5(
        name,
        content='careers',
        content_rowid='rowid',
        tokenize='trigram'
      );`
    );

    // Create triggers for synchronization
    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS careers_ai AFTER INSERT ON careers BEGIN
        INSERT INTO careers_fts(rowid, name)
        VALUES (new.rowid, new.name);
      END;
    `);

    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS careers_ad AFTER DELETE ON careers BEGIN
        INSERT INTO careers_fts(careers_fts, rowid, name)
        VALUES('delete', old.rowid, old.name);
      END;
    `);

    this.db.run(`
      CREATE TRIGGER IF NOT EXISTS careers_au AFTER UPDATE ON careers BEGIN
        INSERT INTO careers_fts(careers_fts, rowid, name)
        VALUES('delete', old.rowid, old.name);
        INSERT INTO careers_fts(rowid, name)
        VALUES (new.rowid, new.name);
      END;
    `);
  }
}
