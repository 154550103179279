import { NotFoundError } from "elysia";
import { redisClient } from "../cache/redisClient";
import { config } from "../config";
import {
  BadRequestError,
  ForbiddenError,
  TooManyRequestsError,
  UnauthorizedError,
} from "../exceptions";
import { createSession, generateSessionToken } from "../lib/auth/session";
import { DatabaseModels } from "../lib/db";
import { Logger } from "../lib/logger";

const LOCKED_ACCOUNT_MESSAGE =
  "Akun Anda telah terkunci. Silakan coba lagi nanti.";

export const Authentication = {
  login: async (
    { username, password }: { username: string; password: string },
    db: DatabaseModels
  ): Promise<string> => {
    const user = db.users.getByName(username);
    if (!user) {
      Logger.error(`User ${username} not found. Someone tried to login!`);
      throw new NotFoundError("Akun tidak ditemukan.");
    }
    await checkLockout(user.id);

    const isPasswordValid = await Bun.password.verify(
      password,
      user.hashedPassword
    );
    if (!isPasswordValid) {
      Logger.error(
        `User ${username} entered wrong password with password: ${password}. Someone tried to login!`
      );
      await handleFailedAttempt(user.id);
      throw new BadRequestError("Password yang Anda masukkan salah.");
    }

    // Reset failed attempts
    await redisClient.del(`${config.cache.failedAttemptsKeyPrefix}${user.id}`);

    const token = generateSessionToken();
    await createSession(token, user.id);
    return token;
  },
  me: (db: DatabaseModels, userId?: string | null) => {
    if (!userId) {
      throw new UnauthorizedError();
    }

    const user = db.users.get(userId);
    if (!user) {
      throw new NotFoundError("User tidak ditemukan.");
    }

    Logger.debug(`User ${user.id} is requesting their own information.`);
    return user;
  },
};

const checkLockout = async (userId: string): Promise<void> => {
  const lockoutKey = `${config.cache.lockoutKeyPrefix}${userId}`;
  try {
    const isLocked = await redisClient.get(lockoutKey);
    if (isLocked) {
      throw new TooManyRequestsError(LOCKED_ACCOUNT_MESSAGE);
    }
  } catch (error) {
    throw error;
  }
};

const handleFailedAttempt = async (userId: string): Promise<void> => {
  const attemptsKey = `${config.cache.failedAttemptsKeyPrefix}${userId}`;

  try {
    const attempts = await redisClient.incr(attemptsKey);

    // Implement exponential backoff
    const delay = Math.min(
      config.cache.fixedDelay * Math.pow(2, attempts),
      30000
    ); // Cap delay at 30 seconds
    await new Promise((resolve) => setTimeout(resolve, delay));

    if (attempts >= config.cache.maxFailedAttempts) {
      const lockoutKey = `${config.cache.lockoutKeyPrefix}${userId}`;
      await redisClient.set(
        lockoutKey,
        "1",
        "EX",
        config.cache.lockoutDuration
      ); // Lock account

      await redisClient.del(`${attemptsKey}`); // Reset failed attempts
      throw new ForbiddenError(LOCKED_ACCOUNT_MESSAGE);
    }
  } catch (error) {
    throw error;
  }
};
