import { DatabaseModels } from "../lib/db";
import { Product } from "../lib/db/product";
import { Paginated } from "../lib/db/root";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";
import { formatToProductCategory, formatToRupiah } from "../utils/string";

const getPaginated = (
  db: DatabaseModels,
  page: number = DEFAULT_CURRENT_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
  name?: string
): Paginated<string[]> => {
  const { data, metadata } = db.products.getPaginated(page, pageSize, name);
  const items = data?.map((d: Product) => [
    d.id.toString(),
    d.name,
    formatToProductCategory(d.category),
    d.category === "juice" && d.secondaryPrice > 0
      ? `(K) ${formatToRupiah(d.price)} / (B) ${formatToRupiah(
          d.secondaryPrice
        )}`
      : formatToRupiah(d.price),
  ]);

  return { data: items, metadata };
};

export const ProductService = {
  getPaginated,
  create: (
    {
      name,
      category,
      price,
      secondaryPrice,
      imageUrl,
      blurhash,
      isHighlighted,
    }: {
      name: string;
      category: "juice" | "dessert" | "food";
      price: number;
      secondaryPrice: number;
      imageUrl: string;
      blurhash: string;
      isHighlighted: boolean;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    db.products.create(
      name,
      category,
      price,
      secondaryPrice,
      imageUrl,
      blurhash,
      isHighlighted
    );
    return getPaginated(db);
  },
  update: (
    id: string,
    {
      name,
      category,
      price,
      secondaryPrice,
      imageUrl,
      blurhash,
      isHighlighted,
    }: {
      name?: string;
      category?: "juice" | "dessert" | "food";
      price?: number;
      secondaryPrice?: number;
      imageUrl?: string;
      blurhash?: string;
      isHighlighted?: boolean;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    db.products.update(
      id,
      name,
      category,
      price,
      secondaryPrice,
      imageUrl,
      blurhash,
      isHighlighted
    );
    return getPaginated(db);
  },
  delete: (id: string, db: DatabaseModels): Paginated<string[]> => {
    db.products.delete(id);
    return getPaginated(db);
  },
};
