import { Html } from "@elysiajs/html";
import { cn } from "../utils/classname";

export type ImageProps = {
  url: string;
  dataUrl: string;
  alt: string;
  width: number;
  height: number;
  customClass?: string;
  parentClass?: string;
  dimensionType?: "fixed" | "fluid";
};

export function Image({
  url,
  dataUrl,
  alt,
  width,
  height,
  customClass = "",
  parentClass = "",
  dimensionType = "fixed",
}: ImageProps): JSX.Element {
  // Generate unique IDs for targeting the elements
  const uniqueId = Math.random().toString(36).substring(2, 9);
  const blurImgId = `blur-img-${uniqueId}`;
  const realImgId = `real-img-${uniqueId}`;

  return (
    <div
      class={cn("relative", parentClass)}
      style={{
        maxWidth: `${width}px`, // Sets the maximum width based on the provided width prop
        width: "100%",
        height: dimensionType === "fluid" ? "100%" : `${height}px`, // Set the height explicitly based on the provided height prop
        overflow: "hidden",
        ...(dimensionType === "fluid" && {
          maxHeight: `${height}px`,
          aspectRatio: `${width}/${height}`,
        }), // Maintain aspect ratio
      }}
    >
      {/* Blurred Placeholder Image */}
      <img
        id={blurImgId}
        src={dataUrl}
        alt={alt}
        class={cn(
          "absolute inset-0 object-cover w-full h-full transition-opacity duration-300 blur-placeholder",
          customClass
        )}
        loading="lazy"
      />

      {/* Real Image */}
      <img
        id={realImgId}
        src={url}
        alt={alt}
        class={cn(
          "absolute inset-0 object-cover w-full h-full opacity-0 transition-opacity duration-300 real-image",
          customClass
        )}
        data-blur-id={blurImgId}
        loading="lazy"
      />
    </div>
  );
}
