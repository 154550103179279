import { t } from "elysia";

export const getProductsValidation = {
  query: t.Object({
    page: t.Optional(t.Number({ error: "Halaman harus berupa angka." })),
    pageSize: t.Optional(
      t.Number({ error: "Ukuran halaman harus berupa angka." })
    ),
  }),
};

export const createProductValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.String({
      error: "Nama diperlukan.",
      minLength: 1,
    }),
    category: t.Enum(
      { juice: "juice", dessert: "dessert", food: "food" },
      {
        error:
          "Kategori harus salah satu dari 'juice', 'dessert', atau 'food'.",
        minLength: 1,
      }
    ),
    price: t.String({
      error: "Harga diperlukan dan harus berupa angka.",
      pattern: "^[0-9]+$",
      minLength: 1,
      maxLength: 12,
    }),
    secondaryPrice: t.Optional(
      t.String({
        error: "Harga harus berupa angka.",
        pattern: "^[0-9]+$",
        minLength: 1,
        maxLength: 12,
      })
    ),
    image: t.File({
      error: "Gambar diperlukan.",
      type: ["image/jpeg", "image/png", "image/webp"],
      maxSize: "10m",
    }),
    isHighlighted: t.String({
      error: "Status sorotan harus berupa boolean.",
      pattern: "^(true|false)$",
      minLength: 1,
    }),
  }),
};

export const updateProductValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.Optional(
      t.String({
        error: "Nama harus berupa string.",
        minLength: 1,
      })
    ),
    category: t.Optional(
      t.Enum(
        { juice: "juice", dessert: "dessert", food: "food" },
        {
          error:
            "Kategori harus salah satu dari 'juice', 'dessert', atau 'food'.",
          minLength: 1,
        }
      )
    ),
    price: t.Optional(
      t.String({
        error: "Harga harus berupa angka.",
        pattern: "^[0-9]+$",
        minLength: 1,
        maxLength: 12,
      })
    ),
    secondaryPrice: t.Optional(
      t.String({
        error: "Harga harus berupa angka.",
        pattern: "^[0-9]+$",
        minLength: 1,
        maxLength: 12,
      })
    ),
    image: t.Optional(
      t.Union([
        t.File({
          error: "Gambar harus berupa file.",
          type: ["image/jpeg", "image/png", "image/webp"],
          maxSize: "10m",
        }),
        t.Undefined(),
      ])
    ),
    isHighlighted: t.Optional(
      t.String({
        error: "Status sorotan harus berupa boolean.",
        pattern: "^(true|false)$",
        minLength: 1,
      })
    ),
  }),
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};

export const deleteProductValidation = {
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};
