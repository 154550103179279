import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../../utils/classname";

interface InputProps extends JSX.HtmlInputTag {
  customClass?: string;
}

export function Input({
  children,
  customClass = "",
  ...props
}: PropsWithChildren<InputProps>): JSX.Element {
  return (
    <div class="uk-form-controls !mx-0">
      <input
        {...props}
        class={cn(
          "uk-input h-auto py-2 rounded-[10px] text-base text-dark-shade border-[#DAD7D5]",
          customClass
        )}
        data-props-name={props.name}
        autocomplete="off"
      />
      <p class="uk-form-help uk-text-danger" id={`${props.name}-error`}></p>
    </div>
  );
}
