import { Html } from "@elysiajs/html";
import { ModalConfirmation } from "../modal";

const CONFIRMATION: any = {
  Active: {
    title: "Nonaktifkan Banner",
    message:
      "Apakah Anda yakin ingin menonaktifkan banner ini? Banner tidak akan ditampilkan lagi setelah dinonaktifkan.",
  },
  Inactive: {
    title: "Konfirmasi Pengaktifan",
    message:
      "Apakah Anda yakin ingin mengaktifkan banner ini? Banner akan tampil di halaman publik setelah diaktifkan.",
  },
};

export function BannerSortable({
  type,
  items,
}: {
  type: string;
  items: string[][];
}): JSX.Element {
  return (
    <div
      hx-put="/banners/reorder"
      hx-trigger="moved delay:500ms"
      hx-include={`[data-id='${type}']`}
      hx-target={`#banners-${type}`}
      hx-boost="true"
      uk-sortable="group: sortable-group cls-no-drag: uk-sortable-nodrag"
      class="flex flex-col gap-6"
    >
      <input type="hidden" name="type" data-id={type} value={type} />
      {items.map((item, index) => (
        <div
          uk-toggle={
            item[0] === "null"
              ? `target: #modal-banners-create`
              : `target: #modal-banners-update`
          }
          hx-get={
            item[0] === "null"
              ? `/banners/create-form?type=${type}`
              : `/banners/${item[0]}/update-form`
          }
          hx-target={
            item[0] === "null" ? `#create-form-banners` : `#update-form-banners`
          }
          hx-boost="true"
          hx-swap="innerHTML"
          class={item[0] === "null" ? "uk-sortable-nodrag" : "cursor-pointer"}
        >
          <input type="hidden" name="order" data-id={type} value={item[0]} />

          <div class="bg-white shadow-6px rounded-[10px] w-full text-dark-shade">
            <ul class="uk-stepper px-6 py-3 gap-4">
              <li class="flex-1 h-10 flex items-center text-medium">
                <span safe>Banner {item[3]}</span>
              </li>
              <li class="flex-1 h-10 flex items-center">
                <span safe>{item[1]}</span>
              </li>
              <li class="w-10 h-10 flex items-center">
                <input
                  id={`toggle-switch-prevent-${index}-${type}`}
                  type="checkbox"
                  name="isActive"
                  checked={item[2] === "Active"}
                  disabled={item[0] === "null"}
                  class="uk-toggle-switch uk-toggle-switch-primary cursor-pointer"
                  uk-toggle={`target: #modal-confirm-activation-${item[0]}`}
                />
              </li>
            </ul>
          </div>

          <ModalConfirmation
            id={`modal-confirm-activation-${item[0]}`}
            title={CONFIRMATION[item[2]]?.title}
            message={CONFIRMATION[item[2]]?.message}
            hx-put={`/banners/${item[0]}`}
            hx-trigger="click"
            hx-target={`#banners-${type}`}
            hx-boost="true"
            hx-swap="innerHTML"
            hx-vals={`{ "isActive": ${!(
              item[2] === "Active"
            )}, "type": "${type}" }`}
          />
        </div>
      ))}
    </div>
  );
}
