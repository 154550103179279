import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { Head } from "../components/head";
import { CreateModal, UpdateModal } from "../components/modal";
import { Layout } from "./layout";
import { BannerSwitcher } from "../components/banners/switcher";
import { BannerSortable } from "../components/banners/sortable";
import { BannerType } from "../lib/db/banner";

export function BannerPage({
  title,
  type,
  items,
}: PropsWithChildren<{
  title: string;
  type: BannerType;
  items: string[][];
  children?: JSX.Element;
}>) {
  return (
    <Head title={title}>
      <Layout currentPath="/banners">
        <main class="py-10 px-6 h-full">
          <h1 class="text-[28px] leading-9 font-bold text-primary-foreground mb-6">
            Pengaturan Banner
          </h1>
          <div id="banners-tab">
            <BannerSwitcher />
            <ul class="uk-switcher uk-margin">
              <li class="w-full" id="banners-main">
                <BannerSortable type={type} items={items} />
              </li>
              <li class="w-full" id="banners-menu"></li>
              <li class="w-full" id="banners-branch"></li>
              <li class="w-full" id="banners-investment"></li>
              <li class="w-full" id="banners-career"></li>
            </ul>
          </div>
          <CreateModal type="banners" title="Tambah Banner" />
          <UpdateModal type="banners" title="Edit Banner" />
        </main>
      </Layout>
    </Head>
  );
}
