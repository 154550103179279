import { Html } from "@elysiajs/html";
import { Dropzone } from "../../dropzone";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Select } from "../../ui/select";
import { Button } from "../../button";

export async function CreateProductForm() {
  return (
    <form
      id="create-form-products"
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-post="/products"
      hx-trigger="submit"
      hx-target="#table-products"
      hx-boost="true"
      hx-swap="outerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Nama Produk</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            required
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-select-category">Kategori Produk</Label>
          <Select
            id="form-horizontal-select-category"
            name="category"
            required
            hx-get="/products/price-input"
            hx-trigger="change"
            hx-target="#price-input-create"
            hx-swap="innerHTML"
          >
            <option value="juice" selected>
              Jus Buah
            </option>
            <option value="dessert">Aneka Es</option>
            <option value="food">Makanan</option>
          </Select>
        </div>

        <div id="price-input-create">
          <div class="uk-margin">
            <Label for="form-horizontal-number-price">Harga Jus Kecil</Label>
            <Input
              id="form-horizontal-number-price"
              type="text"
              name="price"
              required
              data-price
              maxlength={8}
            />
          </div>
          <div class="uk-margin">
            <Label for="form-horizontal-number-secondary-price">
              Harga Jus Besar (Opsional)
            </Label>
            <Input
              id="form-horizontal-number-secondary-price"
              type="text"
              name="secondaryPrice"
              data-omit-if-empty
              data-price
              maxlength={8}
            />
          </div>
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-image-create-product">
            Gambar Produk
          </Label>
          <Dropzone
            id="form-horizontal-image-create-product"
            name="image"
            required
            accept="image/jpeg, image/png, image/webp"
            placeholder="Gambar harus memiliki resolusi 400 x 400 pixels"
          />
        </div>

        <div class="my-6">
          <div class="uk-form-controls">
            <div class="flex items-center space-x-2">
              <input type="hidden" name="isHighlighted" value="false" />
              <input
                class="uk-toggle-switch uk-toggle-switch-primary"
                id="form-toggle-switch"
                type="checkbox"
                name="isHighlighted"
                value="true"
              />
              <Label for="form-toggle-switch" customClass="mb-0">
                Tampilkan pada Halaman Utama
              </Label>
            </div>
          </div>
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[167px]">
            <span id="spinner" class="text-loading-indicator">
              Tambah Produk
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}

export function CreateJuicePriceInput() {
  return (
    <>
      <div class="uk-margin">
        <Label for="form-horizontal-number-price">Harga Jus Kecil</Label>
        <Input
          id="form-horizontal-number-price"
          type="text"
          name="price"
          required
          data-price
          maxlength={8}
        />
      </div>
      <div class="uk-margin">
        <Label for="form-horizontal-number-secondary-price">
          Harga Jus Besar (Opsional)
        </Label>
        <Input
          id="form-horizontal-number-secondary-price"
          type="text"
          name="secondaryPrice"
          required
          data-price
          maxlength={8}
        />
      </div>
    </>
  );
}

export function CreateNonJuicePriceInput() {
  return (
    <>
      <div class="uk-margin">
        <Label for="form-horizontal-number-price">Harga Produk</Label>
        <Input
          id="form-horizontal-number-price"
          type="text"
          name="price"
          required
          data-price
          maxlength={8}
        />
      </div>
    </>
  );
}
