import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import BackdropEllipse from "../components/backdrop";
import { Head } from "../components/head";
import { Eye } from "../components/icons";
import { Input } from "../components/ui/input";

export function LoginPage({
  title,
  redirect,
}: PropsWithChildren<{
  title: string;
  redirect?: string;
  children?: JSX.Element;
}>) {
  return (
    <Head title={title}>
      <div class="relative flex flex-col min-h-screen overflow-hidden">
        <div class="fixed inset-0 -z-20">
          <div class="absolute inset-0 backdrop-blur-[1000px]" />
        </div>

        <main class="flex-1 flex justify-center items-center">
          <div class="uk-card h-fit py-12 w-full sm:w-auto sm:min-w-[392px] max-w-[392px] bg-white rounded-[20px] shadow-6px !border-0">
            <form
              class="uk-form-horizontal"
              hx-post={`/login${redirect ? `?redirect=${redirect}` : ""}`}
              hx-trigger="submit"
              hx-replace-url={redirect ? redirect : "/banners"}
              hx-indicator="#spinner"
              hx-target="body"
            >
              <fieldset class="uk-fieldset">
                <div class="uk-card-header !py-0">
                  <img
                    src="/public/images/icon-primary.webp"
                    alt="Icon Jus Kode"
                    class="w-[150px] h-[150px] mx-auto"
                  />
                </div>
                <div class="uk-card-body !py-0">
                  <div>
                    <div class="mt-2.5">
                      <Input
                        id="username"
                        type="text"
                        placeholder="Nama"
                        name="username"
                        customClass="w-full"
                      />
                    </div>
                  </div>
                  <p
                    class="uk-form-help uk-text-danger"
                    id="username-error"
                  ></p>

                  <div>
                    <label class="uk-form-label !hidden" for="password">
                      Password
                    </label>
                    <div class="mt-4 relative">
                      <input
                        class="uk-input !rounded-[10px] !h-10 border border-[#DAD7D5]"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                      />

                      <button
                        id="toggle-password"
                        type="button"
                        class="absolute inset-y-0 right-3 px-2"
                      >
                        <Eye />
                      </button>
                    </div>
                  </div>
                  <p
                    class="uk-form-help uk-text-danger"
                    id="password-error"
                  ></p>
                </div>

                <div class="uk-card-footer !py-0 mt-4">
                  <button
                    class="bg-primary font-medium py-[9px] px-6 rounded-[10px] text-white w-full items-center justify-center"
                    type="submit"
                  >
                    <div id="spinner" class="text-loading-indicator">
                      Masuk
                    </div>
                    <div
                      id="spinner"
                      class="loading-indicator"
                      uk-spinner="ratio: 0.5"
                    ></div>
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </main>

        {/* Convert static divs to BackdropEllipse components */}
        <BackdropEllipse customClass="-top-[280px] -left-[317px]" />
        <BackdropEllipse customClass="top-[270px] -right-[317px]" />
      </div>
    </Head>
  );
}
