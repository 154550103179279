import { Database } from "bun:sqlite";
import { Logger } from "../logger";

export interface Session {
  id?: number;
  name: string;
  author: string;
}

export class SessionsDatabase {
  private db: Database;

  constructor(_db: Database) {
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("sessions table initialized"))
      .catch(() => Logger.error("sessions table failed to initialize"));
  }

  // Initialize the database
  async init() {
    return this.db.run(`
      CREATE TABLE IF NOT EXISTS sessions (
      id TEXT NOT NULL PRIMARY KEY,
      user_id TEXT NOT NULL,
      expires_at INTEGER NOT NULL,
      FOREIGN KEY (user_id) REFERENCES users(id)
      );
    `);
  }
}
