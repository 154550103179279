import { t } from "elysia";

export const getInvestmentsValidation = {
  query: t.Object({
    page: t.Optional(t.Number({ error: "Halaman harus berupa angka." })),
    pageSize: t.Optional(
      t.Number({ error: "Ukuran halaman harus berupa angka." })
    ),
  }),
};

export const createInvestmentValidation = {
  type: "multipart/form-data",
  body: t.Object({
    branchName: t.String({
      error: "Nama cabang diperlukan.",
      minLength: 1,
    }),
    totalInvestment: t.String({
      error: "Total investasi diperlukan.",
      pattern: "^[0-9]+$",
      minLength: 1,
      maxLength: 12,
    }),
    totalInvestmentCollected: t.String({
      error: "Total investasi terkumpul diperlukan.",
      pattern: "^[0-9]+$",
      minLength: 1,
      maxLength: 12,
    }),
    location: t.String({ error: "Lokasi diperlukan." }),
    googleMapsUrl: t.Optional(
      t.String({
        format: "uri",
        error: "URL Google Maps harus valid.",
        minLength: 1,
      })
    ),
    description: t.String({
      error: "Deskripsi diperlukan.",
      minLength: 1,
    }),
    images: t.Files({
      error: "Gambar diperlukan.",
      type: ["image/jpeg", "image/png", "image/webp"],
    }),
    isActive: t.String({
      error: "Status aktif harus berupa boolean.",
      pattern: "^(true|false)$",
      minLength: 1,
    }),
  }),
};

export const updateInvestmentValidation = {
  type: "multipart/form-data",
  body: t.Object({
    branchName: t.Optional(
      t.String({
        error: "Nama cabang harus berupa string.",
        minLength: 1,
      })
    ),
    totalInvestment: t.Optional(
      t.String({
        error: "Total investasi harus berupa angka.",
        pattern: "^[0-9]+$",
        minLength: 1,
        maxLength: 12,
      })
    ),
    totalInvestmentCollected: t.Optional(
      t.String({
        error: "Total investasi terkumpul harus berupa angka.",
        pattern: "^[0-9]+$",
        minLength: 1,
        maxLength: 12,
      })
    ),
    location: t.Optional(
      t.String({
        error: "Lokasi harus berupa string.",
        minLength: 1,
      })
    ),
    googleMapsUrl: t.Optional(
      t.String({
        format: "uri",
        error: "URL Google Maps harus valid.",
        minLength: 1,
      })
    ),
    description: t.Optional(
      t.String({
        error: "Deskripsi harus berupa string.",
        minLength: 1,
      })
    ),
    images: t.Optional(
      t.Union([
        t.Files({
          error: "Gambar harus berupa file.",
          type: ["image/jpeg", "image/png", "image/webp"],
        }),
        t.Undefined(),
      ])
    ),
    deletedImages: t.Optional(
      t.String({
        error: "ID gambar harus berupa string.",
        minLength: 1,
      })
    ),
    isActive: t.Optional(
      t.String({
        error: "Status aktif harus berupa boolean.",
        pattern: "^(true|false)$",
        minLength: 1,
      })
    ),
  }),
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};

export const deleteInvestmentValidation = {
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};
