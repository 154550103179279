import { cron, Patterns } from "@elysiajs/cron";
import Elysia from "elysia";
import { databaseModels } from "../lib/db";
import { Logger } from "../lib/logger";
import { LogUtil } from "../lib/logger/logUtil";
import { sendSubmissionEmail } from "../lib/mailjet";

export const cronJob = new Elysia().decorate("db", databaseModels).use(
  cron({
    name: "sendSubmissionsEmail",
    pattern: Patterns.EVERY_12_HOURS,
    async run() {
      Logger.info("Start sending submissions email...");
      const submissions = databaseModels.contactSubmissions.getList();

      for (const submission of submissions) {
        try {
          Logger.info(`Sending email to ${submission.email}...`);
          const sent = await sendSubmissionEmail(
            submission.category,
            submission.name,
            submission.email,
            submission.message
          );
          if (sent.response.status === 200) {
            Logger.info(`Email sent to ${submission.email}.`);
            databaseModels.contactSubmissions.update({
              id: submission.id,
              isEmailSent: true,
            });
          } else {
            Logger.error(
              `Email failed to send to ${submission.email}. Status: ${sent.response.statusText}`
            );
          }
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } catch (e: unknown) {
          const error: Error = e as Error;
          Logger.error(
            `Error sending submission email to: ${submission.email}. Message: ${submission.message}`,
            LogUtil.formatError(error, error.name, "cron:sendSubmissionsEmail")
          );
        }
      }
    },
  })
);
