import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { Head } from "../components/head";
import { CreateModal, UpdateModal } from "../components/modal";
import { Layout } from "./layout";
import { Button } from "../components/button";
import { SearchInput } from "../components/search";
import { Plus } from "../components/icons";

export function BranchPage({
  title,
  children: safeChildren,
}: PropsWithChildren<{
  title: string;
  children?: JSX.Element;
}>) {
  return (
    <Head title={title}>
      <Layout currentPath="/branches">
        <main class="py-10 px-6 h-full">
          <h1 class="text-[28px] leading-9 font-bold text-primary-foreground mb-6">
            Lokasi Outlet
          </h1>
          <div class="flex w-full gap-6 mb-6">
            <SearchInput
              id="search-input"
              name="name"
              hx-get="/branches/filter"
              hx-target="#table-branches"
              hx-trigger="keyup changed delay:500ms"
              hx-swap="innerHTML"
              hx-boost="true"
              placeholder="Cari Lokasi"
            />
            <Button
              customClass="flex gap-2.5"
              size="md"
              type="button"
              uk-toggle={`target: #modal-branches-create`}
              hx-get={`/branches/create-form`}
              hx-target={`#create-form-branches`}
              hx-swap="innerHTML"
            >
              <Plus />
              <span>Tambah Lokasi</span>
            </Button>
          </div>
          {safeChildren}
          <CreateModal type="branches" title="Tambah Lokasi" />
          <UpdateModal type="branches" title="Edit Lokasi" />
        </main>
      </Layout>
    </Head>
  );
}
