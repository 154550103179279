import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { CreateCareerForm } from "../components/careers/forms/create";
import { UpdateCareerForm } from "../components/careers/forms/update";
import { Table } from "../components/table";
import { databaseModels } from "../lib/db";
import { PaginationMetadata } from "../lib/db/root";
import { authPlugin } from "../lib/auth/plugin";
import { checkMutationAttempts } from "../lib/rateLimiter";
import { CareerPage } from "../pages/career";
import { CareerService } from "../services/career";
import {
  CAREER_TABLE_HEADER,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../utils/constants";
import { NotificationPayload } from "../utils/notification";
import {
  createCareerValidation,
  deleteCareerValidation,
  updateCareerValidation,
} from "../validation/career";
import { tableValidation } from "../validation/table";

const getPaginated = (
  data: string[][],
  metadata: PaginationMetadata
): JSX.Element => {
  return (
    <Table
      id="table-careers"
      type="careers"
      headings={CAREER_TABLE_HEADER}
      data={data}
      metadata={metadata}
    />
  );
};

export const careerController = new Elysia().group("/careers", (app) =>
  app
    .decorate("db", databaseModels)
    .derive(authPlugin)
    .get("/", ({ db }): JSX.Element => {
      const title = "Jus Kode - Careers";
      const { data, metadata } = CareerService.getPaginated(db);
      return (
        <CareerPage title={title}>{getPaginated(data, metadata)}</CareerPage>
      );
    })
    .get(
      "/filter",
      ({ db, query }): JSX.Element => {
        const page =
          query.page < DEFAULT_CURRENT_PAGE ? DEFAULT_CURRENT_PAGE : query.page;
        const pageSize = DEFAULT_PAGE_SIZE;
        const name = query.name;

        const { data, metadata } = CareerService.getPaginated(
          db,
          page,
          pageSize,
          name
        );
        return getPaginated(data, metadata);
      },
      { ...tableValidation }
    )
    .get("/create-form", ({}): JSX.Element => {
      return <CreateCareerForm />;
    })
    .get("/:id/update-form", ({ params, db }): JSX.Element => {
      const id = params.id;
      const career = db.careers.getById(id);

      if (!career) {
        return <div>Career not found</div>;
      }

      return <UpdateCareerForm career={career} />;
    })
    .post(
      "/",
      async ({ body, db, request, server, set }): Promise<JSX.Element> => {
        await checkMutationAttempts(request, server, "create-career");

        const { data, metadata } = CareerService.create(
          {
            ...body,
            isActive: body.isActive === "true",
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Lowongan pekerjaan telah berhasil ditambahkan.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...createCareerValidation }
    )
    .put(
      "/:id",
      ({ body, db, params, set }): JSX.Element => {
        const id = params.id;

        const { data, metadata } = CareerService.update(
          id,
          {
            ...body,
            isActive: body.isActive ? body.isActive === "true" : undefined,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Lowongan pekerjaan telah berhasil diperbaharui.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...updateCareerValidation }
    )
    .delete(
      "/:id",
      ({ params, db, set }): JSX.Element => {
        const id = params.id;
        const { data, metadata } = CareerService.delete(id, db);

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Lowongan pekerjaan telah berhasil dihapus.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...deleteCareerValidation }
    )
);
