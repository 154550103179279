import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import {
  CreateJuicePriceInput,
  CreateNonJuicePriceInput,
  CreateProductForm,
} from "../components/products/forms/create";
import {
  UpdateJuicePriceInput,
  UpdateNonJuicePriceInput,
  UpdateProductForm,
} from "../components/products/forms/update";
import { Table } from "../components/table";
import { DatabaseModels, databaseModels } from "../lib/db";
import { PaginationMetadata } from "../lib/db/root";
import { authPlugin } from "../lib/auth/plugin";
import { checkMutationAttempts } from "../lib/rateLimiter";
import { ProductPage } from "../pages/product";
import { FileStorage } from "../services/fileStorage";
import { ProductService } from "../services/product";
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
  PRODUCT_TABLE_HEADER,
} from "../utils/constants";
import { NotificationPayload } from "../utils/notification";
import {
  createProductValidation,
  deleteProductValidation,
  updateProductValidation,
} from "../validation/product";
import { tableValidation } from "../validation/table";
import { BadRequestError } from "../exceptions";

const getPaginated = (
  data: string[][],
  metadata: PaginationMetadata
): JSX.Element => {
  return (
    <Table
      id="table-products"
      type="products"
      headings={PRODUCT_TABLE_HEADER}
      data={data}
      metadata={metadata}
    />
  );
};

export const productController = new Elysia().group("/products", (app) =>
  app
    .decorate("db", databaseModels)
    .derive(authPlugin)
    .get("/", async ({ db }): Promise<JSX.Element> => {
      const title = "Jus Kode - Products";
      const { data, metadata } = ProductService.getPaginated(db);
      return (
        <ProductPage title={title}>{getPaginated(data, metadata)}</ProductPage>
      );
    })
    .get(
      "/filter",
      async ({ db, query }): Promise<JSX.Element> => {
        const page =
          query.page < DEFAULT_CURRENT_PAGE ? DEFAULT_CURRENT_PAGE : query.page;
        const pageSize = DEFAULT_PAGE_SIZE;
        const name = query.name;

        const { data, metadata } = ProductService.getPaginated(
          db,
          page,
          pageSize,
          name
        );
        return getPaginated(data, metadata);
      },
      { ...tableValidation }
    )
    .get("/create-form", async ({}): Promise<JSX.Element> => {
      return <CreateProductForm />;
    })
    .get("/:id/update-form", async ({ params, db }): Promise<JSX.Element> => {
      const id = params.id;
      const product = db.products.getById(id);

      if (!product) {
        return <div>Product not found</div>;
      }

      return <UpdateProductForm product={product} />;
    })
    .get(
      "/price-input",
      ({
        query,
      }: {
        query: { category?: "juice" | "dessert" | "food" };
      }): JSX.Element => {
        const category = query.category;
        if (category === "juice") {
          return <CreateJuicePriceInput />;
        }
        return <CreateNonJuicePriceInput />;
      }
    )
    .get(
      "/price-input/:id",
      ({
        params,
        query,
        db,
      }: {
        params: { id: string };
        query: { category?: "juice" | "dessert" | "food" };
        db: DatabaseModels;
      }): JSX.Element => {
        const category = query.category;
        const id = params.id;

        const product = db.products.getById(id);
        if (!product) {
          return <div>Product not found</div>;
        }

        if (category === "juice") {
          return <UpdateJuicePriceInput product={product} />;
        }

        return <UpdateNonJuicePriceInput product={product} />;
      }
    )
    .post(
      "/",
      async ({ body, db, request, server, set }): Promise<JSX.Element> => {
        await checkMutationAttempts(request, server, "create-product");

        const [imageUrl, blurhash] = await FileStorage.upload(body.image);
        const { data, metadata } = ProductService.create(
          {
            ...body,
            imageUrl,
            blurhash,
            price: parseInt(body.price),
            secondaryPrice:
              body.secondaryPrice && body.category === "juice"
                ? parseInt(body.secondaryPrice)
                : 0,
            isHighlighted: body.isHighlighted === "true",
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Produk telah berhasil ditambahkan.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...createProductValidation }
    )
    .put(
      "/:id",
      async ({ body, db, params, set }): Promise<JSX.Element> => {
        const id = params.id;

        const defaultSecondaryPrice = body.category !== "juice" ? 0 : undefined;
        let imageUrl = undefined;
        let blurhash = undefined;
        if (body.image) {
          [imageUrl, blurhash] = await FileStorage.upload(body.image);
        }

        const { data, metadata } = ProductService.update(
          id,
          {
            ...body,
            imageUrl,
            blurhash,
            price: body.price ? parseInt(body.price) : undefined,
            secondaryPrice: body.secondaryPrice
              ? parseInt(body.secondaryPrice)
              : defaultSecondaryPrice,
            isHighlighted: body.isHighlighted
              ? body.isHighlighted === "true"
              : undefined,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Produk telah berhasil diperbaharui.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...updateProductValidation }
    )
    .delete(
      "/:id",
      async ({ params, db, set }): Promise<JSX.Element> => {
        const id = params.id;
        const { data, metadata } = ProductService.delete(id, db);

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Produk telah berhasil dihapus.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...deleteProductValidation }
    )
);
