import { t } from "elysia";

export const loginValidation = {
  body: t.Object({
    username: t.String({
      error: "Nama pengguna harus diisi.",
      minLength: 1,
    }),
    password: t.String({
      error: "Kata sandi harus diisi.",
      minLength: 1,
    }),
  }),
};

export type LoginDTO = typeof loginValidation.body;
