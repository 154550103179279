import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../utils/classname";

interface ButtonProps extends JSX.HtmlButtonTag {
  size?: "sm" | "md" | "lg";
  customClass?: string;
}

const SIZES = {
  sm: "font-medium text-sm py-2.5 px-5",
  md: "font-medium text-base py-3 px-6 max-h-[48px]",
  lg: "font-semibold text-xl py-[14px] px-8",
};

export function Button({
  children,
  size = "lg",
  customClass = "",
  ...props
}: PropsWithChildren<ButtonProps>): JSX.Element {
  return (
    <button
      {...props}
      class={cn(
        "bg-primary rounded-[20px] text-white w-fit text-center",
        SIZES[size],
        customClass
      )}
    >
      {children}
    </button>
  );
}
