import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";

export type HeadProps = {
  title?: string;
  description?: string;
  children?: JSX.Element;
};

export function Head({
  title,
  description = "Jus Kode - Content Management System",
  children,
}: PropsWithChildren<HeadProps>): JSX.Element {
  return (
    <>
      {"<!doctype html>"}
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          {/* SEO Meta Tags */}
          <meta name="description" content={description} />
          <meta name="robots" content="index, follow" />

          {/* Open Graph / Social Media Meta Tags */}
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta
            property="og:image"
            content="https://juskode.id/public/images/seo.avif"
          />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />

          {/* Preload Scripts */}
          <link rel="preload" href="/public/static/htmx/htmx.js" as="script" />
          <link
            rel="preload"
            href="/public/static/htmx/listener.js"
            as="script"
          />
          <link
            rel="preload"
            href="/public/static/htmx/imageTransition.js"
            as="script"
          />

          {/* Preload FrankenUI */}
          <link
            rel="preload"
            href="/public/static/franken/core.iife.min.js"
            as="script"
          />
          <link
            rel="preload"
            href="/public/static/franken/core.min.css"
            as="style"
          />

          {/* Preload Main CSS */}
          <link rel="preload" href="/public/static/main.css" as="style" />

          {/* Scripts */}
          <script src="/public/static/htmx/htmx.js" defer></script>
          <script src="/public/static/htmx/toast.js" defer></script>
          <script src="/public/static/htmx/listener.js" defer></script>
          <script src="/public/static/htmx/dropzone.js" defer></script>
          <script src="/public/static/htmx/imageTransition.js" defer></script>
          <script src="/public/static/htmx/wysiwyg.js" defer></script>

          {/* FrankenUI */}
          <script src="/public/static/franken/core.iife.min.js" defer></script>
          <link href="/public/static/franken/core.min.css" rel="stylesheet" />

          {/* Quill WYSIWYG */}
          <script src="/public/static/quill/quill.js" defer></script>
          <link
            href="/public/static/quill/quill.snow.min.css"
            rel="stylesheet"
          />

          {/* Main CSS */}
          <link href="/public/static/main.css" rel="stylesheet" />
          <link rel="icon" href="/public/favicon.ico" />

          <title safe>{title} | Back Office</title>
        </head>
        <body class="font-inter">{children as "safe"}</body>
      </html>
    </>
  );
}
