import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import {
  CreateBannerForm,
  CreateBannerImageInput,
  CreateBannerVideoInput,
} from "../components/banners/forms/create";
import {
  UpdateBannerForm,
  UpdateBannerImageInput,
  UpdateBannerVideoForm,
} from "../components/banners/forms/update";
import { BannerSortable } from "../components/banners/sortable";
import { databaseModels } from "../lib/db";
import { BannerType } from "../lib/db/banner";
import { BadRequestError } from "../exceptions";
import { authPlugin } from "../lib/auth/plugin";
import { checkMutationAttempts } from "../lib/rateLimiter";
import { BannerPage } from "../pages/banner";
import { BannerService } from "../services/banner";
import { FileStorage } from "../services/fileStorage";
import { NotificationPayload } from "../utils/notification";
import {
  createBannerValidation,
  getBannersValidation,
  updateBannerOrderValidation,
  updateBannerValidation,
} from "../validation/banner";

const constructDropboxUrl = (mediaUrl: string): string => {
  const url = new URL(mediaUrl);
  if (!url.searchParams.has("raw")) {
    url.searchParams.append("raw", "1");
  } else {
    url.searchParams.set("raw", "1");
  }

  // remove dl from the url query
  url.searchParams.delete("dl");

  return url.toString();
};

const getBanners = (type: BannerType, items: string[][]): JSX.Element => {
  const unavailableTotal = 5 - items.length;
  const emptyItems = Array.from({ length: unavailableTotal }, () => []).map(
    (_, index) => [
      "null",
      "-",
      "Inactive",
      (index + 1 + items.length).toString(),
    ]
  );
  items = items.concat(emptyItems);

  return <BannerSortable type={type} items={items} />;
};

export const bannerController = new Elysia().group("/banners", (app) =>
  app
    .decorate("db", databaseModels)
    .derive(authPlugin)
    .get(
      "/",
      ({ db, query }): JSX.Element => {
        const title = "Jus Kode - Banners";
        const type = (query.type as BannerType) || "main";
        let items = BannerService.getList(db, type);

        const unavailableTotal = 5 - items.length;
        const emptyItems = Array.from(
          { length: unavailableTotal },
          () => []
        ).map((_, index) => [
          "null",
          "-",
          "Inactive",
          (index + 1 + items.length).toString(),
        ]);
        items = items.concat(emptyItems);

        return <BannerPage title={title} type={type} items={items} />;
      },
      { ...getBannersValidation }
    )
    .get(
      "/sortable",
      ({ db, query }): JSX.Element => {
        const type = query.type as BannerType;

        const items = BannerService.getList(db, type);
        return getBanners(type, items);
      },
      { ...getBannersValidation }
    )
    .get("/create-form", ({ query }): JSX.Element => {
      const type = query.type as BannerType;
      return <CreateBannerForm type={type} />;
    })
    .get("/:id/update-form", ({ params, db }): JSX.Element => {
      const id = params.id;
      const banner = db.banners.getById(id);

      if (!banner) {
        return <div>Banner not found</div>;
      }

      return <UpdateBannerForm banner={banner} />;
    })
    .get(
      "/media-input",
      ({
        query,
      }: {
        query: { mediaType?: "image" | "video" };
      }): JSX.Element => {
        const mediaType = query.mediaType;

        if (mediaType === "video") {
          return <CreateBannerVideoInput />;
        }

        return <CreateBannerImageInput />;
      }
    )
    .get("/media-input/:id", ({ params, query, db }): JSX.Element => {
      const mediaType = query.mediaType;
      const id = params.id;

      const banner = db.banners.getById(id);
      if (!banner) {
        return <div>Banner not found</div>;
      }

      if (mediaType === "video") {
        return <UpdateBannerVideoForm banner={banner} />;
      }

      return <UpdateBannerImageInput banner={banner} />;
    })
    .post(
      "/",
      async ({ body, db, request, server, set }): Promise<JSX.Element> => {
        await checkMutationAttempts(request, server, "create-banner");

        const type = body.type as BannerType;
        let blurhash = undefined;
        if (body.mediaType === "image" && body.media) {
          [body.mediaUrl, blurhash] = await FileStorage.upload(
            body.media,
            1440,
            600
          );
        }

        if (!body.mediaUrl) {
          throw new BadRequestError("Anda harus mengunggah media.");
        }

        if (body.mediaType === "video") {
          body.mediaUrl = constructDropboxUrl(body.mediaUrl);
        }

        const items = BannerService.create(
          {
            name: body.name,
            type,
            mediaUrl: body.mediaUrl,
            mediaType: body.mediaType,
            blurhash,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Banner telah berhasil ditambahkan.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getBanners(type, items);
      },
      { ...createBannerValidation }
    )
    .put(
      "/reorder",
      ({ body, db }): JSX.Element => {
        const orderMap = new Map<string, number>();
        body.order
          .filter((x) => x != "null")
          .forEach((id, index) => {
            orderMap.set(id, index + 1);
          });

        const type = body.type as BannerType;
        const items = BannerService.updatePriority(db, orderMap, type);
        return getBanners(type, items);
      },
      { ...updateBannerOrderValidation }
    )
    .put(
      "/:id",
      async ({ body, db, params, set }): Promise<JSX.Element> => {
        const id = params.id;
        const type = body.type as BannerType;

        let blurhash = undefined;
        if (body.media && body.media.type !== "video/mp4") {
          [body.mediaUrl, blurhash] = await FileStorage.upload(
            body.media,
            1440,
            600
          );
        }

        if (body.mediaType === "video") {
          if (!body.mediaUrl) {
            throw new BadRequestError("Anda harus mengunggah link Dropbox.");
          }

          body.mediaUrl = constructDropboxUrl(body.mediaUrl);
        }

        const items = BannerService.update(
          id,
          {
            name: body.name,
            type,
            mediaUrl: body.mediaUrl,
            mediaType: body.mediaType,
            blurhash,
            isActive: body.isActive ? body.isActive === "true" : undefined,
          },
          db
        );

        let message = "Banner telah berhasil diperbaharui.";
        if (body && body.isActive === "true") {
          message = "Banner telah berhasil diaktifkan di website.";
        } else if (body && body.isActive === "false") {
          message = "Banner telah berhasil dinonaktifkan di website.";
        }

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message,
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getBanners(type, items);
      },
      { ...updateBannerValidation }
    )
);
