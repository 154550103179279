import { t } from "elysia";

export const getBannersValidation = {
  query: t.Object({
    type: t.Optional(
      t.String({
        error: "Tipe banner diperlukan.",
        pattern: "^(main|menu|branch|investment|career)$",
      })
    ),
  }),
};

export const createBannerValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.String({
      error: "Nama diperlukan.",
      minLength: 1,
    }),
    type: t.String({
      error: "Tipe banner diperlukan.",
      pattern: "^(main|menu|branch|investment|career)$",
      minLength: 1,
    }),
    mediaType: t.String({
      error: "Tipe media diperlukan.",
      pattern: "^(image|video)$",
      minLength: 1,
    }),
    media: t.Optional(
      t.File({
        error: "Media diperlukan.",
        type: ["image/jpeg", "image/png", "image/webp"],
        maxSize: "10m",
      })
    ),
    mediaUrl: t.Optional(
      t.String({
        error: "URL media diperlukan.",
        format: "uri",
        minLength: 1,
      })
    ),
  }),
};

export const updateBannerValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.Optional(
      t.String({
        error: "Nama harus berupa string.",
        minLength: 1,
      })
    ),
    type: t.Optional(
      t.String({
        error: "Tipe diperlukan.",
        pattern: "^(main|menu|branch|investment|career)$",
        minLength: 1,
      })
    ),
    mediaType: t.Optional(
      t.String({
        error: "Tipe media diperlukan.",
        pattern: "^(image|video)$",
        minLength: 1,
      })
    ),
    media: t.Optional(
      t.Union([
        t.File({
          error: "File media tidak valid.",
          type: ["image/jpeg", "image/png", "image/webp"],
          maxSize: "10m",
        }),
        t.Undefined(),
      ])
    ),
    mediaUrl: t.Optional(
      t.String({
        error: "URL media diperlukan.",
        format: "uri",
        minLength: 1,
      })
    ),
    priority: t.Optional(
      t.String({
        error: "Prioritas harus berupa angka!",
        pattern: "^[0-9]+$",
        minLength: 1,
      })
    ),
    isActive: t.Optional(
      t.String({
        error: "Status aktif diperlukan.",
        pattern: "^(true|false)$",
        minLength: 1,
      })
    ),
  }),
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};

export const updateBannerOrderValidation = {
  type: "multipart/form-data",
  body: t.Object({
    type: t.String({
      error: "Tipe banner diperlukan.",
      pattern: "^(main|menu|branch|investment|career)$",
      minLength: 1,
    }),
    order: t.Array(
      t.String({
        error: "ID tidak valid.",
        minLength: 1,
      })
    ),
  }),
};

export const deleteBannerValidation = {
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};
