import { BadRequestError } from "../exceptions";

export function validateTime(time: string): string {
  const [hour, minute] = time.split(":");
  if (parseInt(hour) > 23 || parseInt(minute) > 59) {
    throw new BadRequestError("Format waktu tidak sesuai.");
  }
  return time;
}

export function validateOpeningHours(opening: string, closing: string): void {
  const [openingHour, openingMinute] = opening.split(":");
  const [closingHour, closingMinute] = closing.split(":");

  if (
    parseInt(openingHour) > parseInt(closingHour) ||
    (parseInt(openingHour) === parseInt(closingHour) &&
      parseInt(openingMinute) >= parseInt(closingMinute))
  ) {
    throw new BadRequestError("Waktu buka harus sebelum waktu tutup.");
  }
}
