import { Html } from "@elysiajs/html";
import { Investment } from "../../../lib/db/investment";
import { generateBlurhashDataUrl } from "../../../utils/blurhash";
import { convertBaseURL } from "../../../utils/url";
import { Dropzone, DropzoneImage } from "../../dropzone";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { TextEditor } from "../../ui/textEditor";
import { Button } from "../../button";

export async function UpdateInvestmentForm({
  investment,
}: {
  investment: Investment;
}) {
  const images: DropzoneImage[] = await Promise.all(
    investment.images.map(async (image) => {
      return {
        id: image.id!,
        dataUrl: await generateBlurhashDataUrl(image.blurhash, 32, 32),
        imageUrl: convertBaseURL(image.imageUrl),
      };
    })
  );

  return (
    <form
      id={`update-form-investments`}
      class="uk-form-stacked"
      enctype="multipart/form-data"
      hx-encoding="multipart/form-data"
      hx-put={`/investments/${investment.id}`}
      hx-trigger="submit"
      hx-target={`#table-investments`}
      hx-boost="true"
      hx-swap="outerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-branchName">Nama Outlet</Label>
          <Input
            id="form-horizontal-text-branchName"
            type="text"
            name="branchName"
            value={investment.branchName}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-number-totalInvestment">
            Total Investasi
          </Label>
          <Input
            id="form-horizontal-number-totalInvestment"
            type="text"
            name="totalInvestment"
            value={investment.totalInvestment.toLocaleString("id")}
            data-price
            maxlength={15}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-number-totalInvestmentCollected">
            Total Investasi Terkumpul
          </Label>
          <Input
            id="form-horizontal-number-totalInvestmentCollected"
            type="text"
            name="totalInvestmentCollected"
            value={investment.totalInvestmentCollected.toLocaleString("id")}
            data-price
            maxlength={15}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-location">Lokasi Outlet</Label>
          <Input
            id="form-horizontal-text-location"
            type="text"
            name="location"
            value={investment.location}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-googleMapsUrl">
            Link Google Maps
          </Label>
          <Input
            id="form-horizontal-text-googleMapsUrl"
            type="text"
            name="googleMapsUrl"
            value={investment.googleMapsUrl || ""}
            data-omit-if-empty
          />
        </div>

        <div class="uk-margin" id="wysiwyg">
          <Label for="quill-content">Deskripsi</Label>
          <TextEditor name="description">
            {investment.description as "safe"}
          </TextEditor>
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-image-update-invest">Gambar Outlet</Label>
          <Dropzone
            id="form-horizontal-image-update-invest"
            name="images"
            accept="image/jpeg, image/png, image/webp"
            multiple
            placeholder="Gambar harus memiliki resolusi 1200 x 500 pixels"
            data-omit-if-empty
            deletedImagesId="deleted-images"
            deletedImagesName="deletedImages"
            images={images}
          />
        </div>

        <div class="my-6">
          <div class="uk-form-controls">
            <div class="flex items-center space-x-2">
              <input type="hidden" name="isActive" value="false" />
              <input
                class="uk-toggle-switch uk-toggle-switch-primary"
                checked={Boolean(investment.isActive)}
                id="toggle-switch"
                type="checkbox"
                name="isActive"
                value="true"
              />
              <Label for="toggle-switch" customClass="mb-0">
                Investasi Aktif
              </Label>
            </div>
          </div>
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[203px]">
            <span id="spinner" class="text-loading-indicator">
              Perbaharui Investasi
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}
