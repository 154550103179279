import { Database } from "bun:sqlite";
import { ulid } from "ulid";
import { Logger } from "../logger";
import { toCamelCase } from "../../utils/mapper";
import { BaseDatabase } from "./root";

export interface ContactSubmission {
  id: string;
  name: string;
  email: string;
  phone: string;
  category: SubmissionCategory;
  message: string;
  isEmailSent: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export type SubmissionCategory = "collaboration" | "partnership";

export class ContactSubmissionsDatabase extends BaseDatabase {
  protected db: Database;

  constructor(_db: Database) {
    super(_db);
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("contact_submissions table initialized"))
      .catch(() =>
        Logger.error("contact_submissions table failed to initialize")
      );
  }

  getList(): ContactSubmission[] {
    const results = this.db
      .query(
        `SELECT * FROM contact_submissions WHERE is_email_sent = 0 ORDER BY created_at ASC`
      )
      .all() as object[];
    const data = results.map(toCamelCase) as ContactSubmission[];

    return data;
  }

  update({ id, isEmailSent }: { id: string; isEmailSent: boolean }) {
    this.db
      .query(`UPDATE contact_submissions SET is_email_sent = ? WHERE id = ?`)
      .get(isEmailSent, id);
  }

  // Initialize the database
  async init() {
    this.db.run(
      `CREATE TABLE IF NOT EXISTS contact_submissions (
        id TEXT NOT NULL PRIMARY KEY,
        name TEXT NOT NULL,
        email TEXT NOT NULL,
        phone TEXT NOT NULL,
        category TEXT NOT NULL,
        message TEXT NOT NULL,
        is_email_sent BOOLEAN DEFAULT 0 NOT NULL,
        created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,
        updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP
      );`
    );
  }
}
