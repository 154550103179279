import { Html } from "@elysiajs/html";
import { TV, Table, Briefcase, MapPin, Calculate, LogOut } from "./icons";

const NAV_ITEMS = [
  {
    title: "Banner Halaman",
    icon: TV,
    href: "/banners",
  },
  {
    title: "Produk",
    icon: Table,
    href: "/products",
  },
  {
    title: "Lokasi Outlet",
    icon: MapPin,
    href: "/branches",
  },
  {
    title: "Lowongan Kerja",
    icon: Briefcase,
    href: "/careers",
  },
  {
    title: "Investasi",
    icon: Calculate,
    href: "/investments",
  },
];

export function Navbar({ currentPath }: { currentPath: string }): JSX.Element {
  return (
    <div class="absolute l-0 t-0 h-full flex flex-col px-6 py-10 bg-[#FCFCFC] w-[236px]">
      <div class="mb-[58px] mx-auto">
        <img
          src="/public/images/logo.avif"
          alt="Jus Kode Logo"
          width="144"
          height="46"
        />
      </div>
      <ul class="uk-nav uk-nav-default flex flex-col gap-4">
        {NAV_ITEMS.map((item) => (
          <li class={currentPath === item.href ? "uk-active" : ""}>
            <a
              href={item.href}
              hx-boost="true"
              class="flex gap-[18px] items-center"
            >
              <div class="w-[20px] flex justify-center">{item.icon()}</div>
              <span safe>{item.title}</span>
            </a>
          </li>
        ))}
      </ul>
      <div class="mt-auto">
        <a
          hx-post="/logout"
          hx-boost="true"
          class="cursor-pointer flex gap-4 items-center text-[#FF3B30]"
        >
          <LogOut />
          <span class="font-medium">Logout</span>
        </a>
      </div>
    </div>
  );
}
