import Database from "bun:sqlite";

export interface PaginationMetadata {
  currentPage: number;
  prevPage: number | null;
  nextPage: number | null;
  totalData: number;
  totalPages: number;
}

export interface Paginated<T> {
  data: T[];
  metadata: PaginationMetadata;
}

export abstract class BaseDatabase {
  protected db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  async init(): Promise<void> {
    throw new Error("Method not implemented.");
  }

  inTransaction(fn: () => void) {
    this.db.run("BEGIN TRANSACTION");
    try {
      fn();
      this.db.run("COMMIT");
    } catch (error) {
      this.db.run("ROLLBACK");
      throw error;
    }
  }

  beginTransaction() {
    this.db.run("BEGIN TRANSACTION");
  }

  commitTransaction() {
    this.db.run("COMMIT");
  }

  rollbackTransaction() {
    this.db.run("ROLLBACK");
  }
}
