import { DatabaseModels } from "../lib/db";
import { Banner, BannerType } from "../lib/db/banner";
import { BadRequestError } from "../exceptions";

const getList = (db: DatabaseModels, type: BannerType): string[][] => {
  const data = db.banners.getList(type);

  const items = data?.map((d: Banner) => [
    d.id?.toString(),
    d.name,
    d.isActive ? "Active" : "Inactive",
    d.priority?.toString(),
    new Date(d.createdAt).toLocaleDateString(),
    new Date(d.updatedAt).toLocaleDateString(),
  ]);

  return items;
};

export const BannerService = {
  getList,
  create: (
    {
      name,
      type,
      mediaUrl,
      mediaType,
      blurhash,
    }: {
      name: string;
      type: BannerType;
      mediaUrl: string;
      mediaType: string;
      blurhash?: string;
    },
    db: DatabaseModels
  ): string[][] => {
    const lastPriority = db.banners.getLastPriority(type);
    const priority = lastPriority + 1;

    if (priority > 5) {
      throw new BadRequestError("Anda hanya bisa menambahkan 5 banner.");
    }

    db.banners.create({
      name,
      type,
      mediaUrl,
      mediaType,
      blurhash,
      priority,
    });

    return getList(db, type);
  },
  update: (
    id: string,
    {
      name,
      type,
      mediaUrl,
      mediaType,
      blurhash,
      isActive,
    }: {
      name?: string;
      type: BannerType;
      mediaUrl?: string;
      mediaType?: string;
      blurhash?: string;
      isActive?: boolean;
    },
    db: DatabaseModels
  ): string[][] => {
    db.banners.inTransaction(() => {
      db.banners.update(id, {
        name,
        type,
        mediaUrl,
        mediaType,
        blurhash,
        isActive,
      });
    });

    return getList(db, type);
  },
  updatePriority: (
    db: DatabaseModels,
    orderMap: Map<string, number>,
    type: BannerType
  ): string[][] => {
    db.banners.inTransaction(() => {
      db.banners.adjustPriorities(orderMap);
    });

    return getList(db, type);
  },
};
