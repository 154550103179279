import { Database } from "bun:sqlite";
import { ulid } from "ulid";
import { Logger } from "../logger";
import { toCamelCase } from "../../utils/mapper";

export interface User {
  id: string;
  username: string;
  hashedPassword: string;
}

export class UsersDatabase {
  private db: Database;

  constructor(_db: Database) {
    this.db = _db;
    // Initialize the database
    this.init()
      .then(() => Logger.debug("users table initialized"))
      .catch(() => Logger.error("users table failed to initialize"));
  }

  // Get a user by username
  get(userId: string): User | null {
    const row = this.db.query("SELECT * FROM users WHERE id = ?").get(userId);
    return row ? (toCamelCase(row) as User) : null;
  }

  getByName(name: string): User | null {
    const row = this.db
      .query("SELECT * FROM users WHERE username = ?")
      .get(name);
    return row ? (toCamelCase(row) as User) : null;
  }

  // Create a new user
  create(name: string, password: string): User {
    const row = this.db
      .query(
        `INSERT INTO users (id, username, hashed_password) VALUES (?, ?, ?) RETURNING id, username, hashed_password`
      )
      .get(ulid(), name, password);
    return toCamelCase(row as object) as User;
  }

  // Initialize the database
  async init() {
    return this.db.run(
      `CREATE TABLE IF NOT EXISTS users (
        id TEXT NOT NULL PRIMARY KEY,
        username TEXT NOT NULL UNIQUE,
        hashed_password TEXT NOT NULL
      );`
    );
  }
}
