import { Html } from "@elysiajs/html";
import { Banner } from "../../../lib/db/banner";
import { generateBlurhashDataUrl } from "../../../utils/blurhash";
import { convertBaseURL } from "../../../utils/url";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Select } from "../../ui/select";
import { Button } from "../../button";
import { Dropzone, DropzoneImage } from "../../dropzone";

export async function UpdateBannerForm({ banner }: { banner: Banner }) {
  return (
    <form
      id={`update-form-banners`}
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-put={`/banners/${banner.id}`}
      hx-trigger="submit"
      hx-target={`#banners-${banner.type}`}
      hx-boost="true"
      hx-swap="innerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Nama Banner</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            value={banner.name}
            data-omit-if-empty
          />
        </div>

        <Input
          hidden
          id="form-horizontal-text-type"
          type="text"
          name="type"
          value={banner.type}
        />

        {/* Media Type Selection */}
        <div class="uk-margin">
          <Label for="form-horizontal-select-mediaType">Tipe Banner</Label>
          <Select
            id="form-horizontal-select-mediaType"
            name="mediaType"
            hx-get={`/banners/media-input/${banner.id}`}
            hx-trigger="change"
            hx-target="#media-input-update"
            hx-swap="innerHTML"
            required
          >
            <option value="image" selected={banner.mediaType === "image"}>
              Gambar
            </option>
            <option value="video" selected={banner.mediaType === "video"}>
              Video
            </option>
          </Select>
        </div>

        {/* Media Input (file input or URL) */}
        <div id="media-input-update" class="uk-margin">
          {banner.mediaType === "image" && (
            <UpdateBannerImageInput banner={banner} />
          )}
          {banner.mediaType === "video" && (
            <UpdateBannerVideoForm banner={banner} />
          )}
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[190px]">
            <span id="spinner" class="text-loading-indicator">
              Perbaharui Banner
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}

export async function UpdateBannerVideoForm({ banner }: { banner: Banner }) {
  let mediaUrl = banner.mediaUrl;
  if (banner.mediaType === "image") {
    mediaUrl = "";
  }

  return (
    <>
      <Label for="form-horizontal-video-url">Link Dropbox</Label>
      <Input
        id="form-horizontal-video-url"
        type="url"
        name="mediaUrl"
        value={mediaUrl}
        data-omit-if-empty
      />
    </>
  );
}

export async function UpdateBannerImageInput({ banner }: { banner: Banner }) {
  const images: DropzoneImage[] = [];

  if (banner.mediaType === "image") {
    const dataUrl = await generateBlurhashDataUrl(banner.blurhash, 32, 32);
    if (banner.mediaUrl) {
      images.push({
        id: banner.id,
        dataUrl,
        imageUrl: convertBaseURL(banner.mediaUrl),
      });
    }
  }

  return (
    <>
      <Label for="form-horizontal-media-update-banner">Tambah Gambar</Label>
      <Dropzone
        images={images}
        id="form-horizontal-media-update-banner"
        type="file"
        name="media"
        accept="image/jpeg, image/png, image/webp"
        data-omit-if-empty
        placeholder="Gambar harus memiliki resolusi 1440 x 600 pixels"
      />
    </>
  );
}
