import { t } from "elysia";

export const createBranchValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.String({
      error: "Nama diperlukan.",
      minLength: 1,
    }),
    image: t.File({
      error: "Gambar diperlukan.",
      type: ["image/jpeg", "image/png", "image/webp"],
      maxSize: "10m",
    }),
    address: t.String({
      error: "Alamat diperlukan.",
      minLength: 1,
    }),
    googleMapsUrl: t.Optional(
      t.String({
        format: "uri",
        error: "URL Google Maps harus valid.",
        minLength: 1,
      })
    ),
    weekdayOpeningTime: t.String({
      error: "Jam buka hari kerja diperlukan.",
      pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
      minLength: 1,
    }),
    weekdayClosingTime: t.String({
      error: "Jam tutup hari kerja diperlukan.",
      pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
      minLength: 1,
    }),
    weekendOpeningTime: t.String({
      error: "Jam buka akhir pekan diperlukan.",
      pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
      minLength: 1,
    }),
    weekendClosingTime: t.String({
      error: "Jam tutup akhir pekan diperlukan.",
      pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
      minLength: 1,
    }),
  }),
};

export const updateBranchValidation = {
  type: "multipart/form-data",
  body: t.Object({
    name: t.Optional(
      t.String({
        error: "Nama harus berupa string.",
        minLength: 1,
      })
    ),
    image: t.Optional(
      t.Union([
        t.File({
          error: "Gambar harus berupa file.",
          type: ["image/jpeg", "image/png", "image/webp"],
          maxSize: "10m",
        }),
        t.Undefined(),
      ])
    ),
    address: t.Optional(
      t.String({
        error: "Alamat harus berupa string.",
        minLength: 1,
      })
    ),
    googleMapsUrl: t.Optional(
      t.String({
        format: "uri",
        error: "URL Google Maps harus valid.",
        minLength: 1,
      })
    ),
    weekdayOpeningTime: t.Optional(
      t.String({
        error: "Jam buka hari kerja harus valid.",
        pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
        minLength: 1,
      })
    ),
    weekdayClosingTime: t.Optional(
      t.String({
        error: "Jam tutup hari kerja harus valid.",
        pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
        minLength: 1,
      })
    ),
    weekendOpeningTime: t.Optional(
      t.String({
        error: "Jam buka akhir pekan harus valid.",
        pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
        minLength: 1,
      })
    ),
    weekendClosingTime: t.Optional(
      t.String({
        error: "Jam tutup akhir pekan harus valid.",
        pattern: "^([01]?[0-9]|2[0-3]):[0-5][0-9]$",
        minLength: 1,
      })
    ),
  }),
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};

export const deleteBranchValidation = {
  params: t.Object({
    id: t.String({
      minLength: 1,
    }),
  }),
};
