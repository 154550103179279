type TimeUnit = "ms" | "s" | "m" | "h" | "d" | "w";

export class TimeSpan {
  value: number;
  unit: TimeUnit;

  constructor(value: number, unit: TimeUnit) {
    this.value = value;
    this.unit = unit;
  }

  milliseconds(): number {
    switch (this.unit) {
      case "ms":
        return this.value;
      case "s":
        return this.value * 1000;
      case "m":
        return this.value * 1000 * 60;
      case "h":
        return this.value * 1000 * 60 * 60;
      case "d":
        return this.value * 1000 * 60 * 60 * 24;
      case "w":
        return this.value * 1000 * 60 * 60 * 24 * 7;
      default:
        throw new Error("Invalid time unit");
    }
  }

  seconds(): number {
    return this.milliseconds() / 1000;
  }

  transform(x: number): TimeSpan {
    return new TimeSpan(Math.round(this.milliseconds() * x), "ms");
  }
}

export function isWithinExpirationDate(date: Date): boolean {
  return Date.now() < date.getTime();
}

export function createDate(timeSpan: TimeSpan): Date {
  return new Date(Date.now() + timeSpan.milliseconds());
}
