import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../../utils/classname";

interface TextAreaProps extends JSX.HtmlTextAreaTag {
  customClass?: string;
}

export function TextArea({
  children,
  customClass = "",
  ...props
}: PropsWithChildren<TextAreaProps>): JSX.Element {
  return (
    <div class="uk-form-controls">
      <textarea
        {...props}
        rows={props.rows || "5"}
        class={cn(
          "uk-textarea h-auto py-2 rounded-[10px] text-base text-dark-shade border-[#DAD7D5]",
          customClass
        )}
      >
        {children}
      </textarea>
      <p class="uk-form-help uk-text-danger" id={`${props.name}-error`}></p>
    </div>
  );
}
