import { Html } from "@elysiajs/html";
import { PaginationMetadata } from "../lib/db/root";
import { Pencil, Trash } from "./icons";
import { ModalConfirmation } from "./modal";
import EmptyState from "./emptyState";
import { cn } from "../utils/classname";

export type TableProps = {
  id: string;
  type: "branches" | "products" | "careers" | "investments";
  headings: string[];
  data: (string | undefined)[][];
  metadata: PaginationMetadata;
};

const typeMappping = {
  branches: "Outlet",
  products: "Produk",
  careers: "Lowongan Kerja",
  investments: "Investasi",
};

const getConfirmation = (
  type: keyof typeof typeMappping
): Record<string, { title: string; message: string }> => {
  return {
    Active: {
      title: `Nonaktifkan ${typeMappping[type]}`,
      message: `Apakah Anda yakin ingin menonaktifkan ${typeMappping[type]} ini? ${typeMappping[type]} tidak akan ditampilkan lagi setelah dinonaktifkan.`,
    },
    Inactive: {
      title: "Konfirmasi Pengaktifan",
      message: `Apakah Anda yakin ingin mengaktifkan ${typeMappping[type]} ini? ${typeMappping[type]} akan tampil di halaman publik setelah diaktifkan.`,
    },
  };
};

function getPageNumbers(currentPage: number, totalPages: number) {
  const delta = 2;
  const range: (number | string)[] = [];

  for (let i = 0; i < totalPages; i++) {
    if (
      i === 0 || // First page
      i === totalPages - 1 || // Last page
      (i >= currentPage - delta && i <= currentPage + delta) // Pages around current
    ) {
      range.push(i);
    } else if (range[range.length - 1] !== "...") {
      range.push("...");
    }
  }

  return range;
}

function Pagination({
  type,
  currentPage,
  totalPages,
  pageNumbers,
}: {
  type: string;
  currentPage: number;
  totalPages: number;
  pageNumbers: (number | string)[];
}): JSX.Element {
  return (
    <nav
      hx-trigger="click"
      hx-target={`#table-${type}`}
      hx-swap="innerHTML"
      aria-label="pagination"
      class="ml-auto"
    >
      <ul class="uk-pagination justify-center">
        <li>
          <button
            disabled={currentPage <= 0}
            hx-get={`/${type}/filter?page=${currentPage - 1}`}
            class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]"
          >
            <span uk-pagination-previous></span>
          </button>
        </li>
        {pageNumbers.map((pageNum) =>
          pageNum === "..." ? (
            <li class="uk-disabled">
              <span class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]">
                …
              </span>
            </li>
          ) : (
            <li class={currentPage === pageNum ? "uk-active" : ""}>
              {currentPage === pageNum ? (
                <span
                  aria-current="page"
                  class="bg-primary min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border !border-[#4C5424] text-[#FCFCFC] font-semibold text-[13px] leading-[17.7px]"
                >
                  {(pageNum as number) + 1}
                </span>
              ) : (
                <button
                  hx-get={`/${type}/filter?page=${pageNum}`}
                  class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]"
                >
                  {(pageNum as number) + 1}
                </button>
              )}
            </li>
          )
        )}
        <li>
          <button
            disabled={currentPage >= totalPages - 1}
            hx-get={`/${type}/filter?page=${currentPage + 1}`}
            class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]"
          >
            <span uk-pagination-next></span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export function Table({
  id,
  type,
  headings,
  data,
  metadata,
}: TableProps): JSX.Element {
  const { currentPage, totalPages } = metadata;
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  return (
    <div id={id} class="flex flex-col gap-6">
      <div class="uk-overflow-auto shadow-6px bg-white rounded-[10px] py-4 px-6">
        <table class="uk-table uk-table-divider uk-table-justify">
          <thead>
            <tr>
              {headings?.map((h, index) => (
                <th
                  safe
                  class={`${
                    index < headings.length - 1 ? "uk-table-expand" : "w-[72px]"
                  } !px-0 !py-3 !font-bold !text-[#4C5424] !text-base`}
                >
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => {
              const confirmation = getConfirmation(type);
              let isActiveIndex: number = 4;
              if (type === "investments") {
                isActiveIndex = 5;
              }

              const status = row[isActiveIndex];
              const isStatusExist = !!status;

              return (
                <tr>
                  {row.slice(1, isActiveIndex).map((d) => (
                    <td
                      class="uk-table-expand !px-0 !pb-3 !pt-[22px] text-base text-dark-shade"
                      safe
                    >
                      {d}
                    </td>
                  ))}
                  <td
                    class={cn(
                      "!px-0 !pb-3 !pt-[22px] flex gap-5 items-center",
                      { "w-[124px]": isStatusExist, "w-[72px]": !isStatusExist }
                    )}
                  >
                    {isStatusExist && (
                      <input
                        id={`toggle-switch-prevent-${index}-${type}`}
                        type="checkbox"
                        name="isActive"
                        checked={status === "Active"}
                        disabled={row[0] === "null"}
                        class="uk-toggle-switch uk-toggle-switch-primary cursor-pointer"
                        uk-toggle={`target: #modal-confirm-activation-${row[0]}`}
                      />
                    )}
                    <button
                      class="w-6 h-6 flex items-center justify-center text-primary-foreground"
                      type="button"
                      uk-toggle={`target: #modal-${type}-update`}
                      hx-get={`/${type}/${row[0]}/update-form`}
                      hx-target={`#update-form-${type}`}
                      hx-boost="true"
                      hx-swap="innerHTML"
                    >
                      <Pencil />
                    </button>
                    <button
                      id={`delete-item-table-${type}-${row[0]}`}
                      class="w-6 h-6 flex items-center justify-center text-primary-foreground"
                      type="button"
                      uk-toggle={`target: #modal-delete-item-table-${row[0]}`}
                    >
                      <Trash />
                    </button>

                    {/* Modal Delete Confirmation */}
                    <ModalConfirmation
                      id={`modal-delete-item-table-${row[0]}`}
                      title={`Menghapus ${typeMappping[type]}`}
                      message={`Apakah anda yakin ingin menghapus ${typeMappping[
                        type
                      ].toLowerCase()} ${
                        row[1]
                      }? Data yang dihapus tidak dapat dikembalikan.`}
                      hx-delete={`/${type}/${row[0]}`}
                      hx-target={`#table-${type}`}
                      hx-boost="true"
                      hx-swap="outerHTML"
                    />
                    {isStatusExist && (
                      <ModalConfirmation
                        id={`modal-confirm-activation-${row[0]}`}
                        title={status ? confirmation[status]?.title : ""}
                        message={status ? confirmation[status]?.message : ""}
                        hx-put={`/${type}/${row[0]}`}
                        hx-trigger="click"
                        hx-target={`#table-${type}`}
                        hx-boost="true"
                        hx-swap="innerHTML"
                        hx-vals={`{ "isActive": ${!(
                          status === "Active"
                        )}, "type": "${type}" }`}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {totalPages === 0 && <EmptyState customClass="mt-10" />}

      {/* Pagination */}
      {totalPages > 1 && (
        <Pagination
          type={type}
          currentPage={currentPage}
          totalPages={totalPages}
          pageNumbers={pageNumbers}
        />
      )}
    </div>
  );
}
