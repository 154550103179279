import { DatabaseModels } from "../lib/db";
import { Career } from "../lib/db/career";
import { Paginated } from "../lib/db/root";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";

const getPaginated = (
  db: DatabaseModels,
  page: number = DEFAULT_CURRENT_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
  name?: string
): Paginated<string[]> => {
  const { data, metadata } = db.careers.getPaginated(page, pageSize, name);
  const items = data?.map((d: Career) => [
    d.id.toString(),
    d.name,
    d.division,
    d.location,
    d.isActive ? "Active" : "Inactive",
  ]);

  return { data: items, metadata };
};

export const CareerService = {
  getPaginated,
  create: (
    {
      name,
      division,
      location,
      requirements,
      email,
      isActive,
    }: {
      name: string;
      division: string;
      location: string;
      requirements: string;
      email: string;
      isActive: boolean;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    db.careers.create(name, division, location, requirements, email, isActive);
    return getPaginated(db);
  },
  update: (
    id: string,
    {
      name,
      division,
      location,
      requirements,
      email,
      isActive,
    }: {
      name?: string;
      division?: string;
      location?: string;
      requirements?: string;
      email?: string;
      isActive?: boolean;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    db.careers.update(
      id,
      name,
      division,
      location,
      requirements,
      email,
      isActive
    );
    return getPaginated(db);
  },
  delete: (id: string, db: DatabaseModels): Paginated<string[]> => {
    db.careers.delete(id);
    return getPaginated(db);
  },
};
