import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../utils/classname";

interface EmptyStateProps {
  customClass?: string;
  icon?: JSX.Element;
  title?: string;
  message?: string;
}

const EmptyFile = () => {
  return (
    <svg
      width="137"
      height="121"
      viewBox="0 0 137 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        opacity="0.1"
        cx="69"
        cy="64.5"
        rx="55.5"
        ry="56.5"
        fill="#A7A995"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M86.0504 46.9168L93.0032 97.6266L93.6278 102.714C93.8291 104.353 92.6636 105.845 91.0246 106.046L47.2531 111.421C45.6141 111.622 44.1224 110.456 43.9211 108.817L37.1822 53.933C37.0816 53.1135 37.6643 52.3677 38.4838 52.267C38.489 52.2664 38.4941 52.2658 38.4993 52.2652L42.1308 51.8579M45.0669 51.5283L48.4955 51.1437L45.0669 51.5283Z"
        fill="white"
      />
      <path
        d="M87.2888 46.747C87.195 46.063 86.5645 45.5846 85.8806 45.6783C85.1966 45.7721 84.7182 46.4026 84.8119 47.0866L87.2888 46.747ZM93.0032 97.6266L94.2438 97.4743C94.2431 97.4685 94.2424 97.4627 94.2416 97.4568L93.0032 97.6266ZM93.6278 102.714L94.8685 102.562L93.6278 102.714ZM91.0246 106.046L91.177 107.287L91.0246 106.046ZM47.2531 111.421L47.4054 112.661L47.2531 111.421ZM43.9211 108.817L45.1618 108.665L43.9211 108.817ZM38.4993 52.2652L38.6387 53.5074L38.4993 52.2652ZM42.2701 53.1001C42.9562 53.0231 43.4499 52.4046 43.373 51.7185C43.296 51.0325 42.6775 50.5387 41.9914 50.6156L42.2701 53.1001ZM44.9275 50.2861C44.2415 50.363 43.7477 50.9816 43.8247 51.6676C43.9016 52.3537 44.5202 52.8474 45.2062 52.7705L44.9275 50.2861ZM48.6349 52.3859C49.3209 52.3089 49.8147 51.6904 49.7377 51.0043C49.6608 50.3183 49.0422 49.8245 48.3562 49.9015L48.6349 52.3859ZM84.8119 47.0866L91.7647 97.7964L94.2416 97.4568L87.2888 46.747L84.8119 47.0866ZM91.7625 97.779L92.3871 102.866L94.8685 102.562L94.2438 97.4743L91.7625 97.779ZM92.3871 102.866C92.5042 103.82 91.826 104.688 90.8723 104.805L91.177 107.287C93.5011 107.001 95.1539 104.886 94.8685 102.562L92.3871 102.866ZM90.8723 104.805L47.1007 110.18L47.4054 112.661L91.177 107.287L90.8723 104.805ZM47.1007 110.18C46.147 110.297 45.2789 109.619 45.1618 108.665L42.6804 108.97C42.9658 111.294 45.0813 112.947 47.4054 112.661L47.1007 110.18ZM45.1618 108.665L38.4229 53.7807L35.9415 54.0853L42.6804 108.97L45.1618 108.665ZM38.4229 53.7807C38.4064 53.6464 38.5019 53.5242 38.6361 53.5077L38.3314 51.0264C36.8268 51.2111 35.7568 52.5807 35.9415 54.0853L38.4229 53.7807ZM38.6361 53.5077C38.637 53.5076 38.6378 53.5075 38.6387 53.5074L38.36 51.023C38.3505 51.0241 38.3409 51.0252 38.3314 51.0264L38.6361 53.5077ZM38.6387 53.5074L42.2701 53.1001L41.9914 50.6156L38.36 51.023L38.6387 53.5074ZM45.2062 52.7705L48.6349 52.3859L48.3562 49.9015L44.9275 50.2861L45.2062 52.7705Z"
        fill="#4C5424"
      />
      <path
        d="M52.25 40C52.25 38.4812 53.4812 37.25 55 37.25H87.5566C88.288 37.25 88.9893 37.5414 89.5054 38.0597L98.9488 47.5445C99.4619 48.0598 99.75 48.7575 99.75 49.4847V95C99.75 96.5188 98.5188 97.75 97 97.75H55C53.4812 97.75 52.25 96.5188 52.25 95V40Z"
        fill="white"
        stroke="#4C5424"
        stroke-width="2.5"
      />
      <path
        d="M59 87H78.9535M59 49H78.9535H59ZM59 57.9412H92H59ZM59 67.6275H92H59ZM59 77.3137H92H59Z"
        stroke="#A7A995"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="61.1061" cy="22.6383" r="4.26821" fill="#DAD7D5" />
      <circle cx="115.664" cy="24.554" r="1.91628" fill="#DAD7D5" />
      <circle cx="2.15065" cy="42.9163" r="1.91628" fill="#DAD7D5" />
      <circle cx="133.311" cy="75.1279" r="2.49116" fill="#DAD7D5" />
      <circle cx="16.6474" cy="15.8789" r="2.49116" fill="#DAD7D5" />
      <path
        d="M92.4455 4.77637L94.7384 2.48343L96.3962 4.14121L94.1033 6.43414L96.6947 9.0256L94.948 10.7723L92.3566 8.18084L90.0573 10.4801L88.3995 8.82235L90.6988 6.52306L88.2153 4.03958L89.962 2.29288L92.4455 4.77637Z"
        fill="#FCFCFC"
      />
      <path
        d="M32.4049 27.7187L34.2507 25.8728L35.5853 27.2074L33.7394 29.0533L35.8256 31.1395L34.4195 32.5456L32.3333 30.4594L30.4823 32.3104L29.1477 30.9758L30.9987 29.1248L28.9994 27.1256L30.4056 25.7194L32.4049 27.7187Z"
        fill="#FCFCFC"
      />
      <path
        d="M7.70526 59.0705L8.88159 57.8941L9.73207 58.7446L8.55574 59.9209L9.88522 61.2504L8.98912 62.1465L7.65964 60.817L6.48004 61.9966L5.62956 61.1462L6.80916 59.9666L5.53506 58.6925L6.43116 57.7964L7.70526 59.0705Z"
        fill="#FCFCFC"
      />
      <path
        d="M133.409 47.4982L134.586 46.3219L135.436 47.1723L134.26 48.3487L135.589 49.6782L134.693 50.5743L133.364 49.2448L132.184 50.4244L131.334 49.5739L132.513 48.3943L131.239 47.1202L132.135 46.2241L133.409 47.4982Z"
        fill="#FCFCFC"
      />
    </svg>
  );
};

const EmptyState = ({
  icon = <EmptyFile />,
  title = "Belum Ada Data",
  message = "Saat ini data masih kosong. Silakan tambahkan data baru untuk memulai",
  customClass = "",
}: PropsWithChildren<EmptyStateProps>): JSX.Element => {
  return (
    <div class={cn("flex flex-col gap-3 items-center mx-auto text-center", customClass)}>
      {icon}
      <h4 class="text-xl font-semibold text-dark-grey" safe>
        {title}
      </h4>
      <p class="text-grey" safe>
        {message}
      </p>
    </div>
  );
};

export default EmptyState;
