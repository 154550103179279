import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { Head } from "../components/head";
import { CreateModal, UpdateModal } from "../components/modal";
import { Layout } from "./layout";
import { SearchInput } from "../components/search";
import { Button } from "../components/button";
import { Plus } from "../components/icons";

export function CareerPage({
  title,
  children: safeChildren,
}: PropsWithChildren<{
  title: string;
  children?: JSX.Element;
}>) {
  return (
    <Head title={title}>
      <Layout currentPath="/careers">
        <main class="py-10 px-6 h-full">
          <h1 class="text-[28px] leading-9 font-bold text-primary-foreground mb-6">
            Lowongan Kerja
          </h1>
          <div class="flex w-full gap-6 mb-6">
            <SearchInput
              id="search-input"
              name="name"
              hx-get="/careers/filter"
              hx-target="#table-careers"
              hx-trigger="keyup changed delay:500ms"
              hx-swap="innerHTML"
              hx-boost="true"
              placeholder="Cari Lowongan Kerja"
            />
            <Button
              customClass="flex gap-2.5"
              size="md"
              type="button"
              uk-toggle={`target: #modal-careers-create`}
              hx-get={`/careers/create-form`}
              hx-target={`#create-form-careers`}
              hx-swap="innerHTML"
            >
              <Plus />
              <span>Tambah Lowongan</span>
            </Button>
          </div>
          {safeChildren}
          <CreateModal type="careers" title="Tambah Lowongan Kerja" />
          <UpdateModal type="careers" title="Edit Lowongan Kerja" />
        </main>
      </Layout>
    </Head>
  );
}
