export const formatToRupiah = (value: number): string => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

export const formatToProductCategory = (value: string): string => {
  switch (value) {
    case "juice":
      return "Jus Buah";
    case "dessert":
      return "Aneka Es";
    case "food":
      return "Makanan";
    default:
      return value;
  }
};
