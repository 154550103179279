import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";

export function TextEditor({
  children,
  ...props
}: PropsWithChildren<JSX.HtmlInputTag>): JSX.Element {
  return (
    <div class="uk-form-controls">
      <div id="quill-editor" class="ql-container ql-snow">
        {children}
      </div>
      <input
        {...props}
        style="display: none"
        id="quill-content"
        value={children?.toString()}
      ></input>

      <p class="uk-form-help uk-text-danger" id={`${props.name}-error`}></p>
    </div>
  );
}
