import { Html } from "@elysiajs/html";
import { Button } from "../../button";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Select } from "../../ui/select";
import { Dropzone } from "../../dropzone";

export function CreateBannerForm({ type }: { type: string }): JSX.Element {
  return (
    <form
      id="create-form-banners"
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-post="/banners"
      hx-trigger="submit"
      hx-target={`#banners-${type}`}
      hx-boost="true"
      hx-swap="innerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        {/* Banner Name */}
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Nama Banner</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            required
          />
        </div>

        <Input
          hidden
          id="form-horizontal-text-type"
          type="text"
          name="type"
          value={type}
        />

        {/* Media Type Selection */}
        <div class="uk-margin">
          <Label for="form-horizontal-select-mediaType">Tipe Banner</Label>
          <Select
            id="form-horizontal-select-mediaType"
            name="mediaType"
            hx-get="/banners/media-input"
            hx-trigger="change"
            hx-target="#media-input-create"
            hx-swap="innerHTML"
            required
          >
            <option value="image" selected>
              Gambar
            </option>
            <option value="video">Video</option>
          </Select>
        </div>

        {/* Media Input (file input or URL) */}
        <div id="media-input-create" class="uk-margin">
          <CreateBannerImageInput />
        </div>

        {/* Buttons */}
        <div class="mt-6 flex justify-end">
          <Button
            id="spinner"
            size="md"
            type="submit"
            customClass="min-w-[141px]"
          >
            <span id="spinner" class="text-loading-indicator">
              Buat Banner
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}

export function CreateBannerVideoInput() {
  return (
    <>
      <Label for="form-horizontal-video-url">Link Dropbox</Label>
      <Input
        id="form-horizontal-video-url"
        type="url"
        name="mediaUrl"
        required
      />
    </>
  );
}

export function CreateBannerImageInput() {
  return (
    <>
      <Label for="form-horizontal-media-create-banner">Tambah Gambar</Label>
      <Dropzone
        id="form-horizontal-media-create-banner"
        name="media"
        required
        accept="image/jpeg, image/png, image/webp"
        placeholder="Gambar harus memiliki resolusi 1440 x 600 pixels"
      />
    </>
  );
}
