import { sha256 } from "@oslojs/crypto/sha2";
import {
  encodeBase32LowerCaseNoPadding,
  encodeHexLowerCase,
} from "@oslojs/encoding";
import { redisClient } from "../../cache/redisClient";
import { config } from "../../config";
import { TimeSpan } from "./date";

export function generateSessionToken(): string {
  const bytes = new Uint8Array(20);
  crypto.getRandomValues(bytes);
  const token = encodeBase32LowerCaseNoPadding(bytes);
  return token;
}

export async function createSession(
  token: string,
  userId: string
): Promise<Session> {
  const maxAge = new TimeSpan(config.cookie.maxAge, "s");
  const sessionId = encodeHexLowerCase(sha256(new TextEncoder().encode(token)));
  const session: Session = {
    id: sessionId,
    userId,
    expiresAt: new Date(Date.now() + maxAge.milliseconds()),
  };
  await redisClient.set(
    `session:${session.id}`,
    JSON.stringify({
      id: session.id,
      user_id: session.userId,
      expires_at: Math.floor(session.expiresAt.getTime() / 1000),
    }),
    "EX",
    maxAge.seconds()
  );
  return session;
}

export async function validateSessionToken(
  token: string
): Promise<Session | null> {
  const sessionId = encodeHexLowerCase(sha256(new TextEncoder().encode(token)));
  const item = await redisClient.get(`session:${sessionId}`);
  if (item === null) {
    return null;
  }
  const result = JSON.parse(item);
  const session: Session = {
    id: result.id,
    userId: result.user_id,
    expiresAt: new Date(result.expires_at * 1000),
  };
  if (Date.now() >= session.expiresAt.getTime()) {
    await redisClient.del(`session:${sessionId}`);
    return null;
  }

  const maxAge = new TimeSpan(config.cookie.maxAge, "s");
  if (Date.now() >= session.expiresAt.getTime() - maxAge.milliseconds() / 2) {
    session.expiresAt = new Date(Date.now() + maxAge.milliseconds());
    session.fresh = true;
    await redisClient.set(
      `session:${session.id}`,
      JSON.stringify({
        id: session.id,
        user_id: session.userId,
        expires_at: Math.floor(session.expiresAt.getTime() / 1000),
      }),
      "EX",
      maxAge.seconds()
    );
  }
  return session;
}

export async function invalidateSession(sessionId: string): Promise<void> {
  await redisClient.del(`session:${sessionId}`);
}

export interface Session {
  id: string;
  userId: string;
  expiresAt: Date;
  fresh?: boolean;
}
