import { DatabaseModels } from "../lib/db";
import { Branch } from "../lib/db/branch";
import { Paginated } from "../lib/db/root";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";
import { validateTime } from "../utils/date";

const getPaginated = (
  db: DatabaseModels,
  page: number = DEFAULT_CURRENT_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
  name?: string
): Paginated<string[]> => {
  const { data, metadata } = db.branches.getPaginated(page, pageSize, name);
  const items = data?.map((d: Branch) => [
    d.id.toString(),
    d.name,
    d.address,
    `${d.weekdayOpeningTime} - ${d.weekdayClosingTime} / ${d.weekendOpeningTime} - ${d.weekendClosingTime}`,
  ]);

  return { data: items, metadata };
};

export const BranchService = {
  getPaginated,
  create: (
    {
      name,
      imageUrl,
      blurhash,
      address,
      googleMapsUrl,
      weekdayOpeningTime,
      weekdayClosingTime,
      weekendOpeningTime,
      weekendClosingTime,
    }: {
      name: string;
      imageUrl: string;
      blurhash: string;
      address: string;
      googleMapsUrl?: URL;
      weekdayOpeningTime: string;
      weekdayClosingTime: string;
      weekendOpeningTime: string;
      weekendClosingTime: string;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    db.branches.create(
      name,
      imageUrl,
      blurhash,
      address,
      validateTime(weekdayOpeningTime),
      validateTime(weekdayClosingTime),
      validateTime(weekendOpeningTime),
      validateTime(weekendClosingTime),
      googleMapsUrl
    );
    return getPaginated(db);
  },
  update: (
    id: string,
    {
      name,
      imageUrl,
      blurhash,
      address,
      googleMapsUrl,
      weekdayOpeningTime,
      weekdayClosingTime,
      weekendOpeningTime,
      weekendClosingTime,
    }: {
      name?: string;
      imageUrl?: string;
      blurhash?: string;
      address?: string;
      googleMapsUrl?: URL;
      weekdayOpeningTime?: string;
      weekdayClosingTime?: string;
      weekendOpeningTime?: string;
      weekendClosingTime?: string;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    db.branches.update(
      id,
      name,
      imageUrl,
      blurhash,
      address,
      googleMapsUrl,
      weekdayOpeningTime ? validateTime(weekdayOpeningTime) : undefined,
      weekdayClosingTime ? validateTime(weekdayClosingTime) : undefined,
      weekendOpeningTime ? validateTime(weekendOpeningTime) : undefined,
      weekendClosingTime ? validateTime(weekendClosingTime) : undefined
    );
    return getPaginated(db);
  },
  delete: (id: string, db: DatabaseModels): Paginated<string[]> => {
    db.branches.delete(id);
    return getPaginated(db);
  },
};
