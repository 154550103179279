import sharp, { Sharp } from "sharp";
import { decode, encode } from "blurhash";
import { BadRequestError } from "../exceptions";

const blurPixel = 32;

const resizeImage = async (sharpImage: Sharp): Promise<Sharp> => {
  const metadata = await sharpImage.metadata();

  if (!metadata.width) {
    throw new BadRequestError("No metadata width found for image");
  }

  if (!metadata.height) {
    throw new BadRequestError("No metadata height found for image");
  }

  // Resize the image before converting to WebP
  return sharpImage.resize(blurPixel, blurPixel);
};

export const createBlurhash = async (imageArray: Buffer) => {
  const sharpImage = sharp(imageArray);

  // Directly resize to blurhash size and get raw pixels in one operation
  const { data, info } = await sharpImage
    .resize(blurPixel, blurPixel)
    .ensureAlpha()
    .raw()
    .toBuffer({ resolveWithObject: true });

  const blurhash = encode(
    new Uint8ClampedArray(data),
    blurPixel,
    blurPixel,
    3, // Reduced x components for better performance
    3 // y components
  );
  return blurhash;
};

export async function generateBlurhashDataUrl(
  blurhash: string,
  width = 32,
  height = 32
): Promise<string> {
  try {
    // Decode the blurhash into a pixel array
    const pixels = decode(blurhash, width, height);
    // Convert the pixel array into an image buffer using sharp
    const buffer = await sharp(pixels, {
      raw: {
        width,
        height,
        channels: 4,
      },
    })
      .jpeg()
      .toBuffer();

    // Convert the buffer into a Base64-encoded data URL
    const base64Image = buffer.toString("base64");
    return `data:image/jpeg;base64,${base64Image}`;
  } catch (e) {
    throw new BadRequestError(
      "Terjadi kesalahan saat menyimpan gambar, hubungi administrator."
    );
  }
}
