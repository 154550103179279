import { DatabaseModels } from "../lib/db";
import { Investment, InvestmentImage } from "../lib/db/investment";
import { Paginated } from "../lib/db/root";
import { BadRequestError } from "../exceptions";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";
import { formatToRupiah } from "../utils/string";

const getPaginated = (
  db: DatabaseModels,
  page: number = DEFAULT_CURRENT_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
  name?: string
): Paginated<string[]> => {
  const { data, metadata } = db.investments.getPaginated(page, pageSize, name);
  const items = data?.map((d: Investment) => [
    d.id.toString(),
    d.branchName,
    formatToRupiah(d.totalInvestment),
    formatToRupiah(d.totalInvestmentCollected),
    d.location,
    d.isActive ? "Active" : "Inactive",
  ]);

  return { data: items, metadata };
};

const validateImages = (
  db: DatabaseModels,
  id: string,
  deletedImages: string,
  totalNewImages: number
): string[] => {
  const existingImages = db.investments.getInvestmentImages(id);
  const idsToDelete = deletedImages?.split(",") || [];
  const filteredImages = idsToDelete.filter((image) => {
    return existingImages.includes(image);
  });

  const totalImageCount =
    existingImages.length - filteredImages.length + totalNewImages;

  if (totalImageCount < 1 || totalImageCount > 5) {
    throw new BadRequestError("Gambar harus diisi dan maksimal 5 gambar");
  }

  return filteredImages;
};

export const InvestmentService = {
  getPaginated,
  create: (
    {
      branchName,
      totalInvestment,
      totalInvestmentCollected,
      location,
      googleMapsUrl,
      description,
      investmentImages,
      isActive,
    }: {
      branchName: string;
      totalInvestment: number;
      totalInvestmentCollected: number;
      location: string;
      googleMapsUrl?: URL;
      description: string;
      investmentImages: InvestmentImage[];
      isActive: boolean;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    if (totalInvestment < totalInvestmentCollected) {
      throw new BadRequestError(
        "Total investasi yang terkumpul harus lebih kecil dari total investasi."
      );
    }

    db.investments.create(
      branchName,
      totalInvestment,
      totalInvestmentCollected,
      location,
      description,
      investmentImages,
      isActive,
      googleMapsUrl
    );
    return getPaginated(db);
  },
  update: (
    id: string,
    {
      branchName,
      totalInvestment,
      totalInvestmentCollected,
      location,
      googleMapsUrl,
      description,
      investmentImages,
      isActive,
      deletedImages,
    }: {
      branchName?: string;
      totalInvestment?: number;
      totalInvestmentCollected?: number;
      location?: string;
      googleMapsUrl?: URL;
      description?: string;
      investmentImages: InvestmentImage[];
      isActive?: boolean;
      deletedImages?: string;
    },
    db: DatabaseModels
  ): Paginated<string[]> => {
    if (totalInvestment && totalInvestmentCollected) {
      if (totalInvestment < totalInvestmentCollected) {
        throw new BadRequestError(
          "Total investasi yang terkumpul harus lebih kecil dari total investasi."
        );
      }
    }

    const idsToDelete = validateImages(
      db,
      id,
      deletedImages || "",
      investmentImages.length
    );

    db.investments.inTransaction(() => {
      // Delete images not in the incoming array
      if (idsToDelete.length > 0) {
        db.investments.deleteInvestmentImages(idsToDelete);
      }
      db.investments.update(
        id,
        investmentImages,
        branchName,
        totalInvestment,
        totalInvestmentCollected,
        location,
        description,
        isActive,
        googleMapsUrl
      );
    });
    return getPaginated(db);
  },
  delete: (id: string, db: DatabaseModels): Paginated<string[]> => {
    db.investments.delete(id);
    return getPaginated(db);
  },
};
