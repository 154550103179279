import { Html } from "@elysiajs/html";
import { Career } from "../../../lib/db/career";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { TextEditor } from "../../ui/textEditor";
import { Button } from "../../button";

export async function UpdateCareerForm({ career }: { career: Career }) {
  return (
    <form
      id={`update-form-careers`}
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-put={`/careers/${career.id}`}
      hx-trigger="submit"
      hx-target={`#table-careers`}
      hx-boost="true"
      hx-swap="outerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Nama Posisi</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            value={career.name}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-division">Nama Divisi</Label>
          <Input
            id="form-horizontal-text-division"
            type="text"
            name="division"
            value={career.division}
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-location">Lokasi Penempatan</Label>
          <Input
            id="form-horizontal-text-location"
            type="text"
            name="location"
            value={career.location}
          />
        </div>

        <div class="uk-margin" id="wysiwyg">
          <Label for="quill-content">Persyaratan</Label>
          <TextEditor name="requirements">
            {career.requirements as "safe"}
          </TextEditor>
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-email">Email</Label>
          <Input
            id="form-horizontal-text-email"
            type="email"
            name="email"
            value={career.email}
          />
        </div>

        <div class="my-6">
          <div class="uk-form-controls">
            <div class="flex items-center space-x-2">
              <input type="hidden" name="isActive" value="false" />
              <input
                class="uk-toggle-switch uk-toggle-switch-primary"
                checked={Boolean(career.isActive)}
                id="toggle-switch"
                type="checkbox"
                name="isActive"
                value="true"
              />
              <Label for="toggle-switch" customClass="mb-0">
                Lowongan Aktif
              </Label>
            </div>
          </div>
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[214px]">
            <span id="spinner" class="text-loading-indicator">
              Perbaharui Lowongan
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}
