import { cors } from "@elysiajs/cors";
import { html } from "@elysiajs/html";
import { serverTiming } from "@elysiajs/server-timing";
import { staticPlugin } from "@elysiajs/static";
import { Context, Elysia } from "elysia";
import { ulid } from "ulid";
import { authController } from "./api/authentication";
import { bannerController } from "./api/banner";
import { branchController } from "./api/branch";
import { careerController } from "./api/career";
import { cronJob } from "./api/cron";
import { fileStorageController } from "./api/fileStorage";
import { investmentController } from "./api/investment";
import { notFoundController } from "./api/notFound";
import { productController } from "./api/product";
import { config } from "./config";
import {
  BadRequestError,
  errorHandler,
  ForbiddenError,
  TooManyRequestsError,
  UnauthorizedError,
} from "./exceptions";
import { authPlugin } from "./lib/auth/plugin";
import { Session } from "./lib/auth/session";
import { Logger } from "./lib/logger";
import { LogUtil } from "./lib/logger/logUtil";
import { rateLimiter } from "./lib/rateLimiter";
import {
  constructSecurityHeaders,
  Environment,
  HttpStatusCode,
} from "./utils/constants";

const rateLimitHandler = async (
  set: Context["set"],
  statusCode: number,
  message: string
) => {
  set.headers = {
    "Content-Type": "text/plain",
  };
  return new Response(message, {
    status: statusCode,
  });
};

const authHandler = (
  redirect: Context["redirect"],
  route: Context["route"],
  request: Context["request"],
  set: Context["set"],
  session: Session | null
) => {
  if (request.url.includes("/login") && session) {
    return redirect("/banners");
  }

  if (!request.url.includes("/login") && !session) {
    const lastAccessedUrl = route.split("/")[1];
    const redirectUrl = `/login${
      lastAccessedUrl ? `?redirect=${lastAccessedUrl}` : ""
    }`;

    set.headers = {
      "X-Full-Redirect": redirectUrl,
      "HX-Redirect": redirectUrl,
      "HX-Refresh": "true",
      "HX-Location": redirectUrl,
    };

    const isSwappable = request.headers.get("HX-Target") ?? "";
    if (isSwappable) {
      return new Response("", { status: 200 });
    }

    return redirect(redirectUrl);
  }
};

export const app = new Elysia()
  .use(
    serverTiming({
      allow: config.network.useHttps,
      enabled: config.network.useHttps,
    })
  )
  .use(cors(config.cors))
  .use(staticPlugin())
  .derive(rateLimiter)
  .derive(authPlugin)
  .derive(({ set }) => {
    set.headers["X-Request-Id"] = ulid();
    return {
      requestId: set.headers["X-Request-Id"],
    };
  })
  .error({
    BadRequestError,
    ForbiddenError,
    TooManyRequestsError,
    UnauthorizedError,
  })
  .onError(({ error, set, request, code, requestId, server, redirect }) => {
    const xForwardedFor = request.headers.get("CF-Connecting-IP");
    const clientAddress = server?.requestIP(request)?.address;
    Logger.error(error.message, {
      ...LogUtil.formatError(error, code, request.url),
      requestId,
      clientAddress: xForwardedFor || clientAddress,
    });
    return errorHandler({ error, set, redirect });
  })
  .get("/sm/:path", () => {
    const path = "build/index.js.map";
    return Bun.file(path);
  })
  .get("/robots.txt", ({ redirect }) => {
    return redirect("/public/robots.txt");
  })
  .get("/sitemap.xml", ({ redirect }) => {
    return redirect("/public/sitemap.xml");
  })
  .get("/favicon.ico", ({ redirect }) => {
    return redirect("/public/favicon.ico");
  })
  .get("/health", () => {
    return new Response("OK", { status: 200 });
  })
  .get("/", ({ redirect }) => {
    return redirect("/login");
  })
  .use(cronJob)
  .use(fileStorageController)
  .use(html())
  .use(notFoundController)
  .guard(
    {
      async beforeHandle({
        session,
        statusCode,
        message,
        set,
        redirect,
        route,
        request,
      }) {
        if (config.env !== Environment.DEVELOPMENT) {
          set.headers = constructSecurityHeaders();
        }

        if (
          statusCode === HttpStatusCode.TOO_MANY_REQUESTS ||
          statusCode === HttpStatusCode.BAD_REQUEST
        ) {
          return await rateLimitHandler(set, statusCode, message);
        }

        return authHandler(redirect, route, request, set, session);
      },
    },
    (app) =>
      app
        .use(authController)
        .use(bannerController)
        .use(branchController)
        .use(productController)
        .use(careerController)
        .use(investmentController)
  );

app.listen(config.network.port, () => {
  Logger.info(
    `Server is running now! Check it out on ${config.network.baseUrl}.`
  );
});

export type App = typeof app;
