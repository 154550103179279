import { Html } from "@elysiajs/html";
import { Danger, Close, CloseSmall } from "../components/icons";

type ModalProps = {
  title: string;
  type: string;
};

export function CreateModal({ type, title }: ModalProps): JSX.Element {
  return (
    <div id={`modal-${type}-create`} class="uk-flex-top" uk-modal>
      <div class="uk-modal-body uk-margin-auto-vertical uk-modal-dialog bg-[#FCFCFC] py-4 lg:w-[48rem] xl:w-[1000px]">
        <div class="uk-modal-header flex justify-between py-2 px-0 text-[#222B45] border-b border-[#DAD7D5]">
          <h2 class="uk-modal-title text-xl font-semibold" safe>
            {title}
          </h2>
          <button class="uk-modal-close" type="button">
            <CloseSmall />
          </button>
        </div>
        <div id={`create-form-${type}`} class="mt-6"></div>
      </div>
    </div>
  );
}

export function UpdateModal({ type, title }: ModalProps): JSX.Element {
  return (
    <div id={`modal-${type}-update`} class="uk-flex-top" uk-modal>
      <div class="uk-modal-body uk-margin-auto-vertical uk-modal-dialog bg-[#FCFCFC] py-4 lg:w-[48rem] xl:w-[1000px]">
        <div class="uk-modal-header flex justify-between py-2 px-0 text-[#222B45] border-b border-[#DAD7D5]">
          <h2 class="uk-modal-title text-xl font-semibold" safe>
            {title}
          </h2>
          <button class="uk-modal-close" type="button">
            <CloseSmall />
          </button>
        </div>
        <div id={`update-form-${type}`} class="mt-6"></div>
      </div>
    </div>
  );
}

interface ModalConfirmationProps extends JSX.HtmlButtonTag {
  id: string;
  title?: string;
  message?: string;
}

export function ModalConfirmation({
  id,
  title,
  message,
  ...props
}: ModalConfirmationProps): JSX.Element {
  return (
    <div id={id} uk-modal class="hidden">
      <div class="uk-modal-body uk-modal-dialog flex gap-4 py-5 px-6 rounded-[20px] shadow-6px border-none xl:w-[1200px]">
        <Danger />
        <div class="flex flex-col gap-2 flex-1">
          <h2 class="font-semibold text-xl text-dark-shade" safe>
            {title}
          </h2>
          <span class="text-[#717171]" safe>
            {message}
          </span>
          <div class="flex justify-end gap-2">
            <button
              type="button"
              class="uk-modal-close bg-white py-1.5 px-6 rounded-[10px] font-medium text-primary-foreground"
            >
              Tidak
            </button>
            <button
              {...props}
              type="button"
              class="confirm uk-modal-close bg-primary py-1.5 px-6 rounded-[10px] font-medium text-white"
            >
              Ya
            </button>
          </div>
        </div>
        <button type="button" class="h-fit uk-modal-close">
          <Close />
        </button>
      </div>
    </div>
  );
}
