import { Context } from "elysia";
import { config } from "../../config";
import { Environment } from "../../utils/constants";
import { CookieController } from "./cookie";
import { TimeSpan } from "./date";
import { verifyRequestOrigin } from "./request";
import { Session, validateSessionToken } from "./session";

export async function authPlugin(context: Context): Promise<{
  userId: string | null;
  session: Session | null;
}> {
  const baseSessionCookieAttributes = {
    httpOnly: true,
    secure: config.cookie.secure,
    domain: config.cookie.domain,
    sameSite: (config.cookie.secure ? "lax" : "strict") as "lax" | "strict",
    path: "/",
  };
  const cookieController = new CookieController(
    config.cookie.name,
    baseSessionCookieAttributes,
    {
      expiresIn: new TimeSpan(config.cookie.maxAge, "s"),
    }
  );

  // CSRF check
  if (context.request.method !== "GET") {
    const originHeader = context.request.headers.get("Origin");
    const hostHeader = context.request.headers.get(
      config.env === Environment.PRODUCTION ? "X-Forwarded-Host" : "Host"
    ); // NOTE: You may need to use `X-Forwarded-Host` instead for some reverse proxies

    if (
      !originHeader ||
      !hostHeader ||
      !verifyRequestOrigin(originHeader, [hostHeader])
    ) {
      return {
        userId: null,
        session: null,
      };
    }
  }

  // use headers instead of Cookie API to prevent type coercion
  const cookieHeader = context.request.headers.get("Cookie") ?? "";
  const token = cookieController.parse(cookieHeader);
  if (!token) {
    return {
      userId: null,
      session: null,
    };
  }

  const session = await validateSessionToken(token);
  if (session && session.fresh) {
    const sessionCookie = cookieController.createCookie(token);
    context.cookie[sessionCookie.name].set({
      value: sessionCookie.value,
      ...sessionCookie.attributes,
    });
  }
  if (!session) {
    const sessionCookie = cookieController.createBlankCookie();
    context.cookie[sessionCookie.name].set({
      value: sessionCookie.value,
      ...sessionCookie.attributes,
    });
  }
  return {
    userId: session?.userId ?? null,
    session,
  };
}
