import { Context, NotFoundError, ValidationError } from "elysia";
import { UnauthorizedError } from "./UnauthorizedError";
import { ForbiddenError } from "./ForbiddenError";
import { BadRequestError } from "./BadRequestError";
import { TooManyRequestsError } from "./TooManyRequestsError";
import { SQLiteError } from "bun:sqlite";

export { BadRequestError } from "./BadRequestError";
export { ForbiddenError } from "./ForbiddenError";
export { TooManyRequestsError } from "./TooManyRequestsError";
export { UnauthorizedError } from "./UnauthorizedError";

const defaultError = "something went wrong with us! ( ꩜ ᯅ ꩜;)";
const defaultSqlError = "something went wrong with the database! ( ꩜ ᯅ ꩜;)";

export const errorHandler = ({
  error,
  set,
  redirect,
}: {
  error: Readonly<Error>;
  set: Context["set"];
  redirect: Context["redirect"];
}) => {
  set.status = 500;

  if (error instanceof ValidationError) {
    const errors: Record<string, string> = {};
    for (const val of error.all.values()) {
      if (val.summary) {
        errors[val.path.slice(1)] = String(val.schema.error) || val.summary;
      }
    }

    set.headers = {
      "HX-Trigger": JSON.stringify({ errors }),
    };
    return;
  }

  if (error instanceof UnauthorizedError) {
    set.headers = {
      "HX-Trigger": JSON.stringify({ error: error.message }),
    };

    set.status = 401;
    return error.message;
  }

  if (error instanceof ForbiddenError) {
    set.headers = {
      "HX-Trigger": JSON.stringify({ error: error.message }),
    };

    set.status = 403;
    return error.message;
  }

  if (error instanceof BadRequestError) {
    set.headers = {
      "HX-Trigger": JSON.stringify({ error: error.message }),
    };

    set.status = 400;
    return error;
  }

  if (error instanceof TooManyRequestsError) {
    set.headers = {
      "HX-Trigger": JSON.stringify({ error: error.message }),
    };

    set.status = 429;
    return error.message;
  }

  if (error instanceof NotFoundError) {
    if (
      !set.headers["HX-Target"] &&
      set.headers["access-control-allow-methods"] === "GET"
    ) {
      return redirect("/not-found");
    }
    set.headers = {
      "HX-Trigger": JSON.stringify({ error: error.message }),
    };

    set.status = 404;
    return error.message;
  }

  if (error instanceof SQLiteError) {
    set.headers = {
      "HX-Trigger": JSON.stringify({
        error: defaultSqlError,
      }),
    };
    return defaultSqlError;
  }

  set.headers = {
    "HX-Trigger": JSON.stringify({
      error: defaultError,
    }),
  };
  return defaultError;
};
