import { S3Client } from "bun";
import { config } from "../../config";
import { InternalServerError } from "elysia";

const s3Client = new S3Client({
  accessKeyId: config.minio.accessKey,
  secretAccessKey: config.minio.secretKey,
  bucket: config.minio.bucketName,
  endpoint: config.minio.endpoint,
  region: config.minio.region,
});

const upload = async (
  objectName: string,
  stream: Buffer | string
): Promise<string> => {
  return s3Client
    .write(objectName, stream)
    .then(() => constructPath(objectName))
    .catch((_: Error) => {
      console.log(_);
      throw new InternalServerError(
        "Saat ini terjadi kesalahan saat mengunggah file, silakan coba lagi nanti."
      );
    });
};

const presignedGetObject = (objectName: string, expiry?: number): string => {
  return s3Client.presign(objectName, {
    expiresIn: expiry || config.minio.presignedUrlDuration,
  });
};

const isResourceExist = async (objectName: string): Promise<boolean> => {
  return s3Client
    .exists(objectName)
    .then(() => true)
    .catch(() => false);
};

const constructPath = (objectName: string): string => {
  const safeObjectName = encodeURIComponent(objectName);
  let url = `${config.network.companyProfileUrl}/resources`;
  return `${url}/${safeObjectName}`;
};

export default {
  upload,
  presignedGetObject,
  isResourceExist,
};
