import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { config } from "../config";
import { authPlugin } from "../lib/auth/plugin";
import { invalidateSession } from "../lib/auth/session";
import { databaseModels } from "../lib/db";
import { LoginPage } from "../pages/login";
import { Authentication } from "../services/authentication";
import { loginValidation } from "../validation/authentication";

export const authController = new Elysia()
  .decorate("db", databaseModels)
  .derive(authPlugin)
  .derive(({ set }) => {
    return {
      requestId: set.headers["X-Request-Id"],
    };
  })
  .get("/login", ({ query }): JSX.Element => {
    const title = "Jus Kode - Login";

    return <LoginPage title={title} redirect={query.redirect} />;
  })
  .post(
    "/login",
    async ({ body, cookie, db, redirect, query }) => {
      const sessionId = await Authentication.login(body, db);
      cookie[config.cookie.name].set({
        domain: config.cookie.domain,
        httpOnly: config.cookie.httpOnly,
        secure: config.cookie.secure,
        secrets: config.cookie.secrets,
        maxAge: config.cookie.maxAge,
        sameSite: config.cookie.secure ? "lax" : "strict",
        value: sessionId,
        path: "/",
      });

      if (query.redirect) {
        return redirect(`/${query.redirect}`);
      }

      return redirect("/banners");
    },
    { ...loginValidation }
  )
  .get("/me", ({ userId, db }) => {
    return Authentication.me(db, userId);
  })
  .post("/logout", async ({ session, cookie, set }) => {
    if (session) {
      await invalidateSession(session.id);
    }

    cookie[config.cookie.name].set({
      expires: new Date(0),
    });

    set.headers = {
      "HX-Redirect": "/login",
    };
    return true;
  });
