import { Html } from "@elysiajs/html";
import { Product } from "../../../lib/db/product";
import { generateBlurhashDataUrl } from "../../../utils/blurhash";
import { convertBaseURL } from "../../../utils/url";
import { Button } from "../../button";
import { Dropzone, DropzoneImage } from "../../dropzone";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Select } from "../../ui/select";

export async function UpdateProductForm({ product }: { product: Product }) {
  const dataUrl = await generateBlurhashDataUrl(product.blurhash, 32, 32);
  const images: DropzoneImage[] = [];
  if (product.imageUrl) {
    product.imageUrl = convertBaseURL(product.imageUrl);
    images.push({
      id: product.id,
      dataUrl,
      imageUrl: product.imageUrl,
    });
  }

  return (
    <form
      id={`update-form-products`}
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-put={`/products/${product.id}`}
      hx-trigger="submit"
      hx-target={`#table-products`}
      hx-boost="true"
      hx-swap="outerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Nama Produk</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            value={product.name}
            required
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-select-category">Kategori Produk</Label>
          <Select
            id="form-horizontal-select-category"
            name="category"
            required
            hx-get={`/products/price-input/${product.id}`}
            hx-trigger="change"
            hx-target="#price-input-update"
            hx-swap="innerHTML"
          >
            <option value="juice" selected={product.category === "juice"}>
              Jus Buah
            </option>
            <option value="dessert" selected={product.category === "dessert"}>
              Aneka Es
            </option>
            <option value="food" selected={product.category === "food"}>
              Makanan
            </option>
          </Select>
        </div>
        <div id="price-input-update">
          {product.category === "juice" ? (
            <>
              {" "}
              <div class="uk-margin">
                <Label for="form-horizontal-number-price">
                  Harga Jus Kecil
                </Label>
                <Input
                  id="form-horizontal-number-price"
                  type="text"
                  name="price"
                  required
                  value={product.price.toLocaleString("id")}
                  data-price
                  maxlength={8}
                />
              </div>
              <div class="uk-margin">
                <Label for="form-horizontal-number-secondary-price">
                  Harga Jus Besar (Opsional)
                </Label>
                <Input
                  id="form-horizontal-number-secondary-price"
                  type="text"
                  name="secondaryPrice"
                  data-omit-if-empty
                  value={product.secondaryPrice.toLocaleString("id")}
                  data-price
                  maxlength={8}
                />
              </div>
            </>
          ) : (
            <div class="uk-margin">
              <Label for="form-horizontal-number-price">Harga Produk</Label>
              <Input
                id="form-horizontal-number-price"
                type="text"
                name="price"
                value={product.price.toLocaleString("id")}
                required
                data-price
                maxlength={8}
              />
            </div>
          )}
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-image-update-product">
            Gambar Produk
          </Label>
          <Dropzone
            images={images}
            id="form-horizontal-image-update-product"
            name="image"
            accept="image/jpeg, image/png, image/webp"
            placeholder="Gambar harus memiliki resolusi 400 x 400 pixels"
            data-omit-if-empty
          />
        </div>

        <div class="my-6">
          <div class="uk-form-controls">
            <div class="flex items-center space-x-2">
              <input type="hidden" name="isHighlighted" value="false" />
              <input
                class="uk-toggle-switch uk-toggle-switch-primary"
                checked={Boolean(product.isHighlighted)}
                id="toggle-switch"
                type="checkbox"
                name="isHighlighted"
                value="true"
              />
              <Label for="form-toggle-switch" customClass="mb-0">
                Tampilkan pada Halaman Utama
              </Label>
            </div>
          </div>
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[189px]">
            <span id="spinner" class="text-loading-indicator">
              Perbaharui Produk
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}

export async function UpdateJuicePriceInput({ product }: { product: Product }) {
  return (
    <>
      <div class="uk-margin">
        <Label for="form-horizontal-number-price">Harga Jus Kecil</Label>
        <Input
          id="form-horizontal-number-price"
          type="text"
          name="price"
          value={product.price.toLocaleString("id")}
          required
          data-price
          maxlength={8}
        />
      </div>
      <div class="uk-margin">
        <Label for="form-horizontal-number-secondary-price">
          Harga Jus Besar (Opsional)
        </Label>
        <Input
          id="form-horizontal-number-secondary-price"
          type="text"
          name="secondaryPrice"
          value={product.secondaryPrice.toLocaleString("id")}
          required
          data-price
          maxlength={8}
        />
      </div>
    </>
  );
}

export async function UpdateNonJuicePriceInput({
  product,
}: {
  product: Product;
}) {
  return (
    <>
      <div class="uk-margin">
        <Label for="form-horizontal-number-price">Harga Produk</Label>
        <Input
          id="form-horizontal-number-price"
          type="text"
          name="price"
          value={product.price.toLocaleString("id")}
          required
          data-price
          maxlength={8}
        />
      </div>
    </>
  );
}
