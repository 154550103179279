import { Html } from "@elysiajs/html";
import { Dropzone } from "../../dropzone";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { TextArea } from "../../ui/textarea";
import { Button } from "../../button";

export async function CreateBranchForm() {
  return (
    <form
      id="create-form-branches"
      class="uk-form-stacked"
      hx-encoding="multipart/form-data"
      hx-post="/branches"
      hx-trigger="submit"
      hx-target="#table-branches"
      hx-boost="true"
      hx-swap="outerHTML"
      hx-indicator="#spinner"
    >
      <fieldset class="uk-fieldset px-1.5 md:px-3">
        <div class="uk-margin">
          <Label for="form-horizontal-text-name">Lokasi Outlet</Label>
          <Input
            id="form-horizontal-text-name"
            type="text"
            name="name"
            required
          />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-address">Alamat Outlet</Label>
          <TextArea id="form-horizontal-text-address" name="address" required />
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-text-googleMapsUrl">
            Link Google Maps
          </Label>
          <Input
            id="form-horizontal-text-googleMapsUrl"
            type="text"
            name="googleMapsUrl"
            data-omit-if-empty
          />
        </div>

        <div class="mb-4">
          <Label>Jam Operasional Outlet</Label>
          <div class="flex gap-2 items-center uk-child-width-expand mb-1">
            <Label for="form-weekday-start" customClass="max-w-[125px]">
              Senin - Kamis
            </Label>
            <Input
              id="form-weekday-start"
              type="time"
              name="weekdayOpeningTime"
              placeholder="00:00"
              value="07:00"
              required
            />
            <span class="text-gray-600 text-center max-w-[52px]">hingga</span>
            <Input
              id="form-weekday-end"
              type="time"
              name="weekdayClosingTime"
              placeholder="00:00"
              value="22:00"
              required
            />
          </div>

          <div class="flex gap-2 items-center uk-child-width-expand">
            <Label for="form-weekend-start" customClass="max-w-[125px]">
              Jumat - Minggu
            </Label>
            <Input
              id="form-weekend-start"
              type="time"
              name="weekendOpeningTime"
              placeholder="00:00"
              value="07:00"
              required
            />
            <span class="text-gray-600 text-center max-w-[52px]">hingga</span>
            <Input
              id="form-weekend-end"
              type="time"
              name="weekendClosingTime"
              placeholder="00:00"
              value="23:00"
              required
            />
          </div>
        </div>

        <div class="uk-margin">
          <Label for="form-horizontal-image-create-branch">Gambar Outlet</Label>
          <Dropzone
            id="form-horizontal-image-create-branch"
            name="image"
            required
            accept="image/jpeg, image/png, image/webp"
            placeholder="Gambar harus memiliki resolusi 400 x 400 pixels"
          />
        </div>

        <div class="mt-6 flex justify-end">
          <Button size="md" type="submit" customClass="min-w-[167px]">
            <span id="spinner" class="text-loading-indicator">
              Tambah Outlet
            </span>
            <div
              id="spinner"
              class="loading-indicator"
              uk-spinner="ratio: 0.5"
            ></div>
          </Button>
        </div>
      </fieldset>
    </form>
  );
}
