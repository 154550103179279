import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { CreateBranchForm } from "../components/branches/forms/create";
import { UpdateBranchForm } from "../components/branches/forms/update";
import { Table } from "../components/table";
import { databaseModels } from "../lib/db";
import { PaginationMetadata } from "../lib/db/root";
import { authPlugin } from "../lib/auth/plugin";
import { checkMutationAttempts } from "../lib/rateLimiter";
import { BranchPage } from "../pages/branch";
import { BranchService } from "../services/branch";
import { FileStorage } from "../services/fileStorage";
import {
  BRANCH_TABLE_HEADER,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "../utils/constants";
import { NotificationPayload } from "../utils/notification";
import {
  createBranchValidation,
  deleteBranchValidation,
  updateBranchValidation,
} from "../validation/branch";
import { tableValidation } from "../validation/table";

const getPaginated = (
  data: string[][],
  metadata: PaginationMetadata
): JSX.Element => {
  return (
    <Table
      id="table-branches"
      type="branches"
      headings={BRANCH_TABLE_HEADER}
      data={data}
      metadata={metadata}
    />
  );
};

export const branchController = new Elysia().group("/branches", (app) =>
  app
    .decorate("db", databaseModels)
    .derive(authPlugin)
    .get("/", ({ db }): JSX.Element => {
      const title = "Jus Kode - Branches";
      const { data, metadata } = BranchService.getPaginated(db);
      return (
        <BranchPage title={title}>{getPaginated(data, metadata)}</BranchPage>
      );
    })
    .get(
      "/filter",
      ({ db, query }): JSX.Element => {
        const page =
          query.page < DEFAULT_CURRENT_PAGE ? DEFAULT_CURRENT_PAGE : query.page;
        const pageSize = DEFAULT_PAGE_SIZE;
        const name = query.name;

        const { data, metadata } = BranchService.getPaginated(
          db,
          page,
          pageSize,
          name
        );
        return getPaginated(data, metadata);
      },
      { ...tableValidation }
    )
    .get("/create-form", ({}): JSX.Element => {
      return <CreateBranchForm />;
    })
    .get("/:id/update-form", ({ params, db }): JSX.Element => {
      const id = params.id;
      const branch = db.branches.getById(id);

      if (!branch) {
        return <div>Branch not found</div>;
      }

      return <UpdateBranchForm branch={branch} />;
    })
    .post(
      "/",
      async ({ body, db, request, server, set }): Promise<JSX.Element> => {
        await checkMutationAttempts(request, server, "create-branch");

        const [imageUrl, blurhash] = await FileStorage.upload(body.image);
        const { data, metadata } = BranchService.create(
          {
            ...body,
            imageUrl,
            blurhash,
            googleMapsUrl: body.googleMapsUrl
              ? new URL(body.googleMapsUrl)
              : undefined,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Outlet telah berhasil ditambahkan.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...createBranchValidation }
    )
    .put(
      "/:id",
      async ({ body, db, params, set }): Promise<JSX.Element> => {
        const id = params.id;

        let imageUrl = undefined;
        let blurhash = undefined;
        if (body.image) {
          [imageUrl, blurhash] = await FileStorage.upload(body.image);
        }

        const { data, metadata } = BranchService.update(
          id,
          {
            ...body,
            imageUrl,
            blurhash,
            googleMapsUrl: body.googleMapsUrl
              ? new URL(body.googleMapsUrl)
              : undefined,
          },
          db
        );

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Outlet telah berhasil diperbaharui.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...updateBranchValidation }
    )
    .delete(
      "/:id",
      ({ params, db, set }): JSX.Element => {
        const id = params.id;
        const { data, metadata } = BranchService.delete(id, db);

        const notificationPayload: NotificationPayload = {
          event: "notification",
          type: "success",
          message: "Outlet telah berhasil dihapus.",
        };
        set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

        return getPaginated(data, metadata);
      },
      { ...deleteBranchValidation }
    )
);
