import { Environment } from "../utils/constants";

const defaultCachePort = 6380;
const defaultMinioPresignedUrlDuration = 3000; // 5 minutes

export const config = {
  env: Bun.env.NODE_ENV || Environment.DEVELOPMENT.toString(),
  cache: {
    host: Bun.env.REDIS_HOST || "127.0.0.1",
    port: Bun.env.REDIS_PORT
      ? parseInt(Bun.env.REDIS_PORT, 10)
      : defaultCachePort,
    username: Bun.env.REDIS_USERNAME,
    pass: Bun.env.REDIS_PASSWORD,
    tls: Bun.env.REDIS_TLS_ENABLED === "true",
    ttl: Bun.env.REDIS_TTL ? parseInt(Bun.env.REDIS_TTL, 10) : 3600,
    maxFailedAttempts: parseInt(Bun.env.MAX_FAILED_ATTEMPTS || "5"),
    lockoutDuration: parseInt(Bun.env.LOCKOUT_DURATION || "1800"), // 30 minutes in seconds
    fixedDelay: parseInt(Bun.env.FIXED_DELAY || "1000"), // 1 second
    lockoutKeyPrefix: Bun.env.LOCKOUT_KEY_PREFIX || "lockout:",
    failedAttemptsKeyPrefix:
      Bun.env.FAILED_ATTEMPTS_KEY_PREFIX || "failedAttempts:",
    mutationAttemptsKeyPrefix: "mutationAttempts:", // This is not configurable
    mutationLockoutDuration: parseInt(Bun.env.LOCKOUT_DURATION || "5"), // 5 seconds in seconds
  },
  logger: {
    logFolder: Bun.env.LOG_FOLDER || Bun.env.PWD + "/logs/",
    logOutput: Bun.env.LOG_OUTPUT || "stdout,file",
    logLevel: Bun.env.LOG_LEVEL || "info",
  },
  db: {
    sqlitePath: Bun.env.DB_SQLITE_PATH || "storage.db",
    backupPath: Bun.env.DB_BACKUP_PATH || "storage.db.backup",
  },
  minio: {
    endpoint: Bun.env.MINIO_ENDPOINT || "127.0.0.1",
    accessKey: Bun.env.MINIO_ROOT_USER || "",
    secretKey: Bun.env.MINIO_ROOT_PASSWORD || "",
    bucketName: Bun.env.MINIO_BUCKET_NAME || "",
    region: Bun.env.MINIO_REGION || "",
    presignedUrlDuration: Bun.env.MINIO_PRESIGNED_URL_DURATION
      ? parseInt(Bun.env.MINIO_PRESIGNED_URL_DURATION, 10)
      : defaultMinioPresignedUrlDuration,
  },
  network: {
    baseUrl: Bun.env.BASE_URL || "",
    companyProfileUrl: Bun.env.COMPANY_PROFILE_URL || "",
    mediaUrl: Bun.env.MEDIA_URL || "",
    port: Number(Bun.env.PORT) || 3001,
    cdnBaseUrl: Bun.env.CDN_BASE_URL || "",
    useProxy: Bun.env.USE_PROXY === "true",
    useHttps: Bun.env.USE_HTTPS === "true",
    limitUploadSize: Bun.env.LIMIT_UPLOAD_SIZE
      ? parseInt(Bun.env.LIMIT_UPLOAD_SIZE, 10)
      : 1 * 1024 * 1024, // 1MB
    windowMs: Number(Bun.env.RATE_LIMIT_WINDOW_MS || 10 * 1000), // 10 seconds
    maxRequests: Number(Bun.env.RATE_LIMIT_MAX_REQUESTS || 15), // Limit each IP to 15 requests per `window` (here, per 10 seconds).
  },
  cookie: {
    name: Bun.env.COOKIE_NAME || "session",
    domain: Bun.env.COOKIE_DOMAIN || "",
    secure: Bun.env.USE_HTTPS === "true",
    secrets: ["Vengeance will be mine", "Fischl von Luftschloss Narfidort"],
    httpOnly: Bun.env.USE_HTTPS === "true",
    sameSite: "lax",
    maxAge: parseInt(Bun.env.COOKIE_MAX_AGE || "3600"), // default 1 hour
  },
  secret: {
    sessionKey: Bun.env.SESSION_KEY || "",
  },
  cors: {
    origin: Bun.env.BASE_URL || "*",
  },
  mailer: {
    mailjet: {
      from: Bun.env.MJ_FROM || "no-reply@juskode.id",
      fromName: Bun.env.MJ_FROM_NAME || "Jus Kode - Support",
      apiKey: Bun.env.MJ_APIKEY_PUBLIC || "",
      apiSecret: Bun.env.MJ_APIKEY_PRIVATE || "",
    },
  },
};
