import Elysia from "elysia";
import { FileStorage } from "../services/fileStorage";
import { config } from "../config";

export const fileStorageController = new Elysia().get(
  "resources/:name",
  async ({ redirect, params, set }) => {
    const url = await FileStorage.getPresignedUrl(params.name);
    const mediaUrl = config.network.mediaUrl;
    set.headers["cache-control"] = "public, max-age=18000, immutable";

    if (
      mediaUrl.includes("localhost") ||
      mediaUrl.includes("127.0.0.1") ||
      mediaUrl.includes("0.0.0.0")
    ) {
      return redirect(url, 302);
    }

    const urlObject = new URL(url);
    const redirectUrl = `${mediaUrl}/${config.minio.bucketName}/${params.name}${urlObject.search}`;
    const handler = redirect(redirectUrl, 302);
    return handler;
  }
);
